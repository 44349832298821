import React, { useEffect, useState } from "react";
import axiosInstance from "../service/axiosInstance";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import Divider from "@mui/material/Divider";
import { useMediaQuery, Popover } from "@mui/material";

import {
  Box,
  Typography,
  Tabs,
  Tab,
  Card,
  CardContent,
  Tooltip,
} from "@mui/material";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import ChecklistIcon from "@mui/icons-material/Checklist";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import CloseIcon from "@mui/icons-material/Close";
import SettlementListCompo from "../DashboardComponents/SettlementListCompo";
import "./style.css";
import { useNavigate } from "react-router-dom";
import Loading from "../Components/Loading";
import API_ENDPOINTS from "../service/apiEndpoints";
import apiClient from "../service/apiClient";
import apiService from "../service/apiService";
import { settlementListingApiData, USER_REPORT } from "../service/ApiList";
import SomethingWrong from "./SomethingWrong";
import { toast } from "react-toastify";

// Mock component for settlement details
const SettlementDetails = ({ account }) => {
  return (
    <div>
      {account.map((item, index) => (
        <div key={index}>{item}</div>
      ))}
    </div>
  );
};

const MySettlement = () => {
  const isMobile = useMediaQuery("(max-width:1100px)");
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [experianData, setExperianData] = useState("");
  const [listingData, setListingData] = useState("");
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // State for popover
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    setLoading(true);
    // const token = localStorage.getItem("TDFToken");
    // axiosInstance
    //   .get("/tdf/api/user/getReport", {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   })
    //   .then((res) => {
    //     setLoading(false);
    //     const data = res?.data;
    //     setExperianData(data);
    //   })
    //   .catch((err) => {
    //     setLoading(false);

    //     //   toast.error(err?.message);
    //   });

    USER_REPORT((res, err) => {
      if (res) {
        const data = res?.data;
        setExperianData(data);
        setLoading(false);
        setIsError(false);
      } else {
        setLoading(false);
        setIsError(true);
      }
    });
  }, []); // Dependency array includes experianData

  useEffect(() => {
    const token = localStorage.getItem("TDFToken");
    // axiosInstance
    //   .post(
    //     "/tdf/api/user/settlement/listing",
    //     {}, // Empty request body if not needed
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     setListingData(res?.data?.response);
    //   })
    //   .catch((err) => {
    //     console.error("Error fetching data:", err);
    //   });

    settlementListingApiData({}, (res, err) => {
      if (res) {
        setListingData(res?.data?.response);
        setIsError(false);
      } else {
        setIsError(true);
      }
    });
  }, []);
  const ErrorHandleing = () => {
    if (isError) {
      toast.error("Something went wrong. Please try again.");
      setTimeout(()=>{
        handleLogout()
      },5000)
    }
  };
  useEffect(() => {   
    ErrorHandleing();
  }, [isError]);

  // Static mock data for settlements
  const reportData = {
    ALL: ["Settlement 1", "Settlement 2", "Settlement 3"],
    IN_PROGRESS: ["Settlement 1"],
    PARTIALLY_PAID: ["Settlement 2"],
    EXPIRED: [],
    CLOSED: ["Settlement 3"],
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleLogout = () => {
    localStorage.clear("experianData");
    localStorage.clear("TDFToken");
    localStorage.clear("loginConfirm");
    navigate("/");
  };
  const handlegoProfile = () => {
    navigate("/dashboard/profile");
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); // Open popover
  };

  const handleClose = () => {
    setAnchorEl(null); // Close popover
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;


  return (
    <>
      {loading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <Box sx={{ padding: { xs: "20px", md: "32", lg: "32px" } }}>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Typography
                  sx={{
                    color: "#044a67",
                    fontSize: { xs: "18px", sm: "20px", lg: "20px" },
                    fontFamily: "CustomFontMedium",
                  }}
                >
                  Hi {experianData?.myReport?.name}{" "}
                  {experianData?.myReport?.surName}!
                </Typography>
                <Typography
                  sx={{
                    color: "#044a67",
                    fontSize: { xs: "18px", sm: "20px", lg: "20px" },
                    fontFamily: "CustomFontMedium",
                  }}
                >
                  It's good to see you again.
                </Typography>
              </Box>

              {!isMobile && (
                <Box style={{ display: "flex" }}>
                  <SettingsIcon
                    style={{ marginRight: "20px", cursor: "pointer" }}
                    onClick={handleClick}
                  />

                  <Tooltip title="Logout">
                    <LogoutIcon
                      onClick={handleLogout}
                      style={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                  {/* Popover for Settings */}
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <Box p={2}>
                      <Typography
                        onClick={handlegoProfile}
                        style={{ cursor: "pointer" }}
                      >
                        Profile
                      </Typography>
                    </Box>
                  </Popover>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "16px",
                width: "100%",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 600,
                  fontSize: 20,
                  fontFamily: "CustomFontMedium",
                }}
              >
                My Settlements
              </Typography>

              <Box sx={{ width: "100%" }}>
                {/* <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  aria-label="settlement tabs"
                  indicatorColor="#044A67"
                  // sx={{ overflow: "scroll" }}
                  scrollButtons="auto"
                  sx={{
                    "& .MuiTabs-indicator": {
                      backgroundColor: "#044A67", // This will change the indicator line color
                    },
                    "& .MuiTab-root": {
                      color: "default", // Default color for unselected tabs
                    },
                    "& .Mui-selected": {
                      color: "#044A67 !important", // Force selected text color
                    },
                    "& .MuiTab-iconWrapper": {
                      color: "default", // Default color for unselected icons
                    },
                    "& .Mui-selected .MuiTab-iconWrapper": {
                      color: "#044A67 !important", // Force selected icon color
                    },
                  }}
                >
                  <Tab
                    iconPosition="start"
                    icon={<ListAltIcon />}
                    label={`All (${listingData?.ALL?.length})`}
                    {...a11yProps(0)}
                    indicatorColor="#044A67"
                    sx={{textTransform:"capitalize"}}
                  />
                  <Tab
                    iconPosition="start"
                    icon={<PlaylistAddCheckIcon />}
                    label={`In Progress (${listingData?.IN_PROGRESS?.length})`}
                    {...a11yProps(1)}
                    indicatorColor="#044A67"
                    sx={{textTransform:"capitalize"}}
                  />
                  <Tab
                    iconPosition="start"
                    icon={<ChecklistIcon />}
                    label={`Partially Paid (${listingData?.PARTIALLY_PAID?.length})`}
                    {...a11yProps(2)}
                    indicatorColor="#044A67"
                    sx={{textTransform:"capitalize"}}
                  />
                  <Tab
                    iconPosition="start"
                    icon={<AvTimerIcon />}
                    label={`Expired/Unpaid (${listingData?.EXPIRED?.length})`}
                    {...a11yProps(3)}
                    indicatorColor="#044A67"
                    sx={{textTransform:"capitalize"}}
                  />
                  <Tab
                    iconPosition="start"
                    icon={<CloseIcon />}
                    label={`Closed (${listingData?.CLOSED?.length})`}
                    {...a11yProps(4)}
                    indicatorColor="#044A67"
                    sx={{textTransform:"capitalize"}}
                  />
                </Tabs> */}
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  aria-label="settlement tabs"
                  scrollButtons="auto"
                  sx={{
                    "& .MuiTabs-indicator": {
                      backgroundColor: "#044A67", // Custom indicator color
                    },
                    "& .MuiTab-root": {
                      color: "default", // Default color for unselected tabs
                    },
                    "& .Mui-selected": {
                      color: "#044A67 !important", // Force selected text color
                    },
                    "& .MuiTab-iconWrapper": {
                      color: "default", // Default color for unselected icons
                    },
                    "& .Mui-selected .MuiTab-iconWrapper": {
                      color: "#044A67 !important", // Force selected icon color
                    },
                  }}
                >
                  <Tab
                    iconPosition="start"
                    icon={<ListAltIcon />}
                    label={`All (${
                      listingData?.ALL?.length ? listingData?.ALL?.length : 0
                    })`}
                    {...a11yProps(0)}
                    sx={{ textTransform: "capitalize" }}
                  />
                  <Tab
                    iconPosition="start"
                    icon={<PlaylistAddCheckIcon />}
                    label={`In Progress (${
                      listingData?.IN_PROGRESS?.length
                        ? listingData?.IN_PROGRESS?.length
                        : 0
                    })`}
                    {...a11yProps(1)}
                    sx={{ textTransform: "capitalize" }}
                  />
                  <Tab
                    iconPosition="start"
                    icon={<ChecklistIcon />}
                    label={`Partially Paid (${
                      listingData?.PARTIALLY_PAID?.length
                        ? listingData?.PARTIALLY_PAID?.length
                        : 0
                    })`}
                    {...a11yProps(2)}
                    sx={{ textTransform: "capitalize" }}
                  />
                  <Tab
                    iconPosition="start"
                    icon={<AvTimerIcon />}
                    label={`Expired/Unpaid (${
                      listingData?.EXPIRED?.length
                        ? listingData?.EXPIRED?.length
                        : 0
                    })`}
                    {...a11yProps(3)}
                    sx={{ textTransform: "capitalize" }}
                  />
                  <Tab
                    iconPosition="start"
                    icon={<CloseIcon />}
                    label={`Closed (${
                      listingData?.CLOSED?.length
                        ? listingData?.CLOSED?.length
                        : 0
                    })`}
                    {...a11yProps(4)}
                    sx={{ textTransform: "capitalize" }}
                  />
                </Tabs>

                <Divider />
                <TabPanel value={value} index={0}>
                  <Card>
                    <CardContent style={{ background: "#EBEBEB" }}>
                      {listingData?.ALL?.length === 0 ? (
                        <Typography
                          sx={{
                            padding: "5px",
                            fontFamily: "CustomFontMedium",
                          }}
                        >
                          No Settlements Found
                        </Typography>
                      ) : (
                        <SettlementListCompo
                          listingData={listingData?.ALL && listingData?.ALL}
                        />
                      )}
                    </CardContent>
                  </Card>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Card>
                    <CardContent style={{ background: "#EBEBEB" }}>
                      {listingData?.IN_PROGRESS?.length === 0 ? (
                        <Typography
                          style={{
                            padding: "5px",
                            fontFamily: "CustomFontMedium",
                          }}
                        >
                          No Settlements Found
                        </Typography>
                      ) : (
                        <SettlementListCompo
                          listingData={
                            listingData?.IN_PROGRESS && listingData?.IN_PROGRESS
                          }
                        />
                      )}
                    </CardContent>
                  </Card>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Card>
                    <CardContent style={{ background: "#EBEBEB" }}>
                      {listingData?.PARTIALLY_PAID?.length === 0 ? (
                        <Typography
                          style={{
                            padding: "5px",
                            fontFamily: "CustomFontMedium",
                          }}
                        >
                          No Settlements Found
                        </Typography>
                      ) : (
                        <SettlementListCompo
                          listingData={
                            listingData?.PARTIALLY_PAID &&
                            listingData?.PARTIALLY_PAID
                          }
                        />
                      )}
                    </CardContent>
                  </Card>
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <Card>
                    <CardContent style={{ background: "#EBEBEB" }}>
                      {listingData?.EXPIRED?.length === 0 ? (
                        <Typography
                          style={{
                            padding: "5px",
                            fontFamily: "CustomFontMedium",
                          }}
                        >
                          No Settlements Found
                        </Typography>
                      ) : (
                        <SettlementListCompo
                          listingData={
                            listingData?.EXPIRED && listingData?.EXPIRED
                          }
                        />
                      )}
                    </CardContent>
                  </Card>
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <Card>
                    <CardContent style={{ background: "#EBEBEB" }}>
                      {listingData?.CLOSED?.length === 0 ? (
                        <Typography
                          style={{
                            padding: "5px",
                            fontFamily: "CustomFontMedium",
                          }}
                        >
                          No Settlements Found
                        </Typography>
                      ) : (
                        <SettlementListCompo
                          listingData={
                            listingData?.CLOSED && listingData?.CLOSED
                          }
                        />
                      )}
                    </CardContent>
                  </Card>
                </TabPanel>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

// Accessibility properties for each tab
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// Component to render each tab's panel
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ marginTop: "20px" }}>{children}</Box>}
    </div>
  );
}

export default MySettlement;
