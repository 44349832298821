import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Popover,
  Box,
  Tooltip,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import PieChartIcon from "@mui/icons-material/PieChart";
import Face6Icon from "@mui/icons-material/Face6";
import QuizIcon from "@mui/icons-material/Quiz";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import whiteLogo from "../assets/imgs/DUE-FACTORY-white-logo.webp";
import TDF from "../assets/imgs/TDF.webp";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import "./sidebar.css"

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null); // Use anchorEl for Popover
  const isMobile = useMediaQuery("(max-width:1100px)");


  const location = useLocation(); // Get the current location
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget); // Set the anchor element for the popover
  };
  const handlegohome = () => {
    navigate("/dashboard/reports");
  };
  const handlegohomeBtn = () => {
    navigate("/");
  };

  const handleClosePopover = () => {
    setAnchorEl(null); // Close the popover
  };

  const handleLogout = () => {
    localStorage.clear(); // Clear all localStorage
    navigate("/"); // Navigate to home page
  };

  const handlegoProfile = () => {
    navigate("/dashboard/profile");
  };


  const handleMenuItemClick = (action, path) => {
    if (action) {
      action();
    } else if (path) {
      navigate(path);
    }
    if (isMobile) {
      handleClosePopover(); // Close the popover on mobile
    }
  };

  const drawerWidth = isOpen ? "18%" : 60;

  const desktopMenuItems = [
    {
      text: "My Credit Reports",
      icon: (
        <Tooltip title="Credit Reports" placement="right-start">
          <ReceiptLongIcon style={{ fontSize: "25px" }} />
        </Tooltip>
      ),
      path: "/dashboard/reports",
      target: "_self", // Open in the same tab
    },
    {
      text: "My Settlement",
      icon: (
        <Tooltip title="My Settlement" placement="right-start">
          <PieChartIcon />
        </Tooltip>
      ),
      path: "/dashboard/settlement",
      target: "_self", // Open in the same tab
    }, 
    {
      text: "Contact Us",
      icon: (
        <Tooltip title="Contact Us" placement="right-start">
          <Face6Icon />
        </Tooltip>
      ),
      path: "/contact-us",
      target: "_blank", // Open in a new tab
    },
    {
      text: "FAQ's",
      icon: (
        <Tooltip title="FAQ's" placement="right-start">
          <QuizIcon />
        </Tooltip>
      ),
      path: "/faq",
      target: "_blank", // Open in a new tab
    },
    {
      text: "Credit Insights",
      icon: (
        <Tooltip title="Credit Insights" placement="right-start">
          <DashboardIcon />
        </Tooltip>
      ),
      path: "/creditdetails",
      target: "_blank", 
      // target: "_self", // Open in the same tab
    },
 


  ];

  const mobileMenuItems = [
    ...desktopMenuItems,
    {
      text: "Profile",
      icon: (
        <Tooltip title="Profile" placement="right-start">
          <PersonIcon style={{ cursor: "pointer" }} />
        </Tooltip>
      ),
      action: handlegoProfile, // Custom action for profile
    },
    {
      text: "Home",
      icon: (
        <Tooltip title="Home" placement="right-start">
          <HomeIcon style={{ cursor: "pointer" }} />
        </Tooltip>
      ),
      action: handlegohomeBtn, // Custom action for profile
    },
    {
      text: "Log out",
      icon: (
        <Tooltip title="Logout" placement="right-start">
          <LogoutIcon style={{ cursor: "pointer" }} />
        </Tooltip>
      ),
      action: handleLogout, // Custom action for logout
    },
  ];



  return (
    <Box sx={{ display: { xs: "block", sm: "block", lg: "flex", md: "flex" } }}>
      {!isMobile && (
        <Drawer
          variant="permanent"
          open={isOpen}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              backgroundColor: "#044a67",
              color: "#fff",
              // borderRadius: "0 35px 35px 0",
              overflowX: "hidden",
              transition: "width 0.3s",
            },
          }}
        >
          <div
            style={{
              // padding: "12px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: isOpen ? "row" : "column",
              marginTop: "40px",
            }}
          >
            {isOpen && (
              <div>
                <img
                  src={whiteLogo}
                  alt="logo"
                  onClick={handlegohome}
                  className="dashborde-logo"
                />
              </div>
            )}
            {!isOpen && (
              <img
                src={TDF}
                alt="TDF logo"
                onClick={handlegohome}
                style={{
                  width: "30px",
                  height: "30px",
                  marginBottom: "8px",
                  cursor: "pointer",
                }}
              />
            )}
            <IconButton onClick={toggleDrawer} style={{ color: "#fff" }}>
              {isOpen ? (
                <ArrowBackIosIcon
                  style={{
                    fontSize: "16px", position: "relative",
                    // right: "-15px"
                  }}
                />
              ) : (
                <ArrowForwardIosIcon style={{ fontSize: "16px" }} />
              )}
            </IconButton>
          </div>
          <List style={{ marginTop: "32px" }}>

            {desktopMenuItems.map((item, index) => (
              <ListItem
                button
                key={index}
                component={item.target === "_blank" ? "a" : Link}
                href={item.target === "_blank" ? item.path : undefined}
                to={item.target === "_blank" ? undefined : item.path}
                target={item.target}
                sx={{
                  color:
                    location.pathname === item.path ? "#44cdff" : "inherit",
                  marginBottom: "10px",
                  whiteSpace:"pre"
                }}
              >



                {!isOpen && (
                  <ListItemIcon sx={{
                    color: "#fff",
                    marginRight: "-15px"
                  }}>
                    {item.icon}
                  </ListItemIcon>
                )}


                {isOpen && (
                  // <ListItemText primary={item.text} sx={{ margin: 0 }} />
                  <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "25px" }}>
                    {/* <span style={{ fontSize: "16px", fontFamily: "'Roboto'", fontWeight: "400"}}> */}
                    {item.icon}
                    {/* </span> */}
                    <Typography sx={{ fontSize: { xs: "14px", sm: "14px", md: "14px", lg: "14px", xl: "16px" }, fontFamily: "Roboto", fontWeight: "400", marginLeft: "10px" }}>{item.text}</Typography>

                  </Box>
                )}
              </ListItem>
            ))}

          </List>
        </Drawer>
      )}
      {isMobile && (
        <>
          <IconButton
            onClick={handleMenuClick}
            style={{ color: "#000", position: "absolute", top: 12, right: 15 }}
          >
            <MoreVertIcon />
          </IconButton>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Box
              sx={{
                color: "#fff",
                borderRadius: "10px",
                padding: "10px",
              }}
            >
            
              <div>
  {mobileMenuItems.map((item, index) => (
    <div
      key={index}
      component={item.path ? (item.target === "_blank" ? "a" : Link) : "button"}
      href={item.target === "_blank" ? item.path : undefined}
      to={item.target === "_blank" ? undefined : item.path}
      onClick={() => handleMenuItemClick(item.action, item.path)}
      target={item.target}
      sx={{
        backgroundColor: location.pathname === item.path ? "#e0e9f1" : "inherit",
        textTransform: "capitalize",
        fontFamily: "CustomFontMedium",
      }}
    >
      <Grid item>
        <Button
          sx={{
            color: "black",
            textTransform: "capitalize",
            fontFamily: "CustomFontMedium",
          }}
        >
      <span className="icon-wrapper">
  {React.cloneElement(item.icon, { fontSize: "16px" })}
</span>
          <div style={{ fontSize: "12px" }}>{item.text}</div>
        </Button>
      </Grid>
    </div>
  ))}
</div>

            </Box>
          </Popover>
        </>
      )}
      <main style={{ flexGrow: 1 }}>
        <Outlet />
      </main>
    </Box>
  );
};

export default Sidebar;
