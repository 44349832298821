import React, { useEffect, useRef, useState } from "react";
import "./style.css";

import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { IoTrendingUp } from "react-icons/io5";

ChartJS.register(ArcElement, Tooltip);
const DoughnutChart = ({ propData }) => {
    const { latestAmt, prevAmt } = propData;
    const data = {
        labels: [], // No labels as legend is disabled
        datasets: [
            {
                data: [latestAmt, prevAmt], // Values for the chart
                backgroundColor: ["#7CB342", "black"], // Colors for the sections
                borderWidth: 0, // Removes border
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false, // Disable legend
            },
            tooltip: {
                enabled: true, // Disable tooltip
            },
        },
        cutout: "80%", // Adjust the doughnut hole size
        rotation: 90,
    };

    return (
        <div style={{ width: "80px" }}>
            <div className="chart-inner-trend">
                {
                    latestAmt
                        ?
                        <IoTrendingUp style={{ fontSize: 25, color: "#7CB342" }} />
                        :
                        <div style={{ height: 5, width: 20, background: "black", borderRadius: 5 }}></div>
                }

            </div>
            <Doughnut data={data} options={options} />
        </div>
    );
};

const DetailCard = ({ data }) => {
    const { title, latestAmt, prevAmt, totalAmt } = data;
    return (<>
        <div className="credit-mix-top-card">
            <div className="credit-mix-top-card-head">{title}</div>
            <div className="credit-mix-top-card-chart">
                <div className="credit-mix-top-card-chart-left">
                    <DoughnutChart propData={data} />
                </div>
                <div className="credit-mix-top-card-chart-right">

                    <div className="credit-mix-top-card-chart-right-child">
                        <div>
                            <span style={{ background: "#7CB342" }}></span>
                            <p>Latest Amount Added</p>
                        </div>
                        <span style={{ color: "#7CB342" }}>₹{latestAmt?.toLocaleString('en-IN')}</span>
                    </div>
                    <div className="credit-mix-top-card-chart-right-child">
                        <div>
                            <span style={{ background: "#000" }}></span>
                            <p>Previous Amount </p>
                        </div>
                        <span>₹{prevAmt?.toLocaleString('en-IN')}</span>
                    </div>
                    <div className="credit-mix-top-card-chart-right-child">
                        <div>
                            <span style={{ background: "red" }}></span>
                            <p>No Data Available</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="credit-mix-top-card-footer">
                <span>₹{totalAmt?.toLocaleString('en-IN')}</span>
                <p>Total amount you have till date</p>
            </div>
        </div>
    </>)
}
const CreditMixNew = ({ data }) => {
    const { ALL, GOOD, BAD } = data.insightCreditMix ?? {};
    const {
        totalAccounts,
        securedAccounts,
        unsecuredAccounts,
        creditCardAccounts,
        totalAmount,
        amountAddedLastThreeMonths,
        totalOutstandingAmount,
        outstandingAmountAddedLastThreeMonths,
        totalCollateralAmount,
        collateralAmountAddedInLastThreeMonths,
        totalSecuredAmount,
        outstandingSecureAmount,
        secureAmountPastDue,
        secureActiveAccounts,
        secureClosedAccounts,
        secureLoanAccountListDTOS,
        totalUnsecuredAmount,
        outstandingUnsecureAmount,
        unsecureAmountPastDue,
        unsecureActiveAccounts,
        unsecureClosedAccounts,
        unsecureLoanAccountListDTOS,
        totalCreditAmount,
        outstandingCreditAmount,
        creditAmountPastDue,
        creditActiveAccounts,
        creditClosedAccounts,
        creditAccountListDTOS
    } = ALL ?? {}
    const [activeTab, setActiveTab] = useState(0); // State to track active tab
    const tabs = ["All Accounts", "Good Accounts", "Bad Accounts", "Bad Accounts", "Bad Accounts"];
    const tabRefs = useRef([]);
    const [indicatorStyle, setIndicatorStyle] = useState({ width: 0, left: 0 });

    useEffect(() => {
        // Update the indicator position and width dynamically
        if (tabRefs.current[activeTab]) {
            const tab = tabRefs.current[activeTab];
            setIndicatorStyle({
                width: tab.offsetWidth,
                left: tab.offsetLeft,
            });
        }
    }, [activeTab]);

    const dataList = [
        { key: "cred-mix-1", title: "Total Amount", latestAmt: 108283, prevAmt: 283829, totalAmt: 2738923 },
        { key: "cred-mix-2", title: "Total Outstanding Amount", latestAmt: 108283, prevAmt: 283829, totalAmt: 2738923 },
        { key: "cred-mix-3", title: "Total Collateral Amount", latestAmt: 108283, prevAmt: 283829, totalAmt: 2738923 },
    ]
    return (
        <div className="credit-mix-main">
            <div className="credit-mix-inner">
                <div className="credit-mix-head">
                    <span>Credit Mix</span>
                </div>
                <div className="credit-mix-nav">
                    <div
                        className="credit-mix-nav-active"
                        style={{
                            transform: `translateX(${indicatorStyle.left - 40}px)`,
                            width: `${indicatorStyle.width + 1}px`,
                            transition: "transform 0.3s ease, width 0.3s ease",
                        }}
                    ></div>
                    <ul>
                        {tabs.map((tab, index) => (
                            <li
                                key={index}
                                ref={(el) => (tabRefs.current[index] = el)}
                                className={
                                    activeTab === index
                                        ? "credit-mix-nav-active-text"
                                        : ""
                                }
                                onClick={() => setActiveTab(index)}
                            >
                                {tab} (0)
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="credit-mix-top-cards">
                    <div className="credit-mix-top-card"></div>
                    {/* <div className="credit-mix-top-card">
                        <div className="credit-mix-top-card-head">Total Amount</div>
                        <div className="credit-mix-top-card-chart">
                            <div className="credit-mix-top-card-chart-left">
                                <DoughnutChart />
                            </div>
                            <div className="credit-mix-top-card-chart-right">

                                <div className="credit-mix-top-card-chart-right-child">
                                    <div>
                                        <span style={{ background: "#7CB342" }}></span>
                                        <p>Latest Amount Added</p>
                                    </div>
                                    <span style={{ color: "#7CB342" }}>₹5,70,500</span>
                                </div>
                                <div className="credit-mix-top-card-chart-right-child">
                                    <div>
                                        <span style={{ background: "#000" }}></span>
                                        <p>Previous Amount </p>
                                    </div>
                                    <span>₹65,70,500</span>
                                </div>
                                <div className="credit-mix-top-card-chart-right-child">
                                    <div>
                                        <span style={{ background: "red" }}></span>
                                        <p>No Data Available</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="credit-mix-top-card-footer">
                            <span>₹5,70,500</span>
                            <p>Total amount you have till date</p>
                        </div>
                    </div> */}
                    {dataList.map((item, index) =>
                        < DetailCard key={"cred-mix-" + index} data={item} />
                    )}
                </div>

            </div>
        </div>
    );
};

export default CreditMixNew;
