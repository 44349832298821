import React, { useEffect, useState } from 'react';
import "./style.css";
import TDF_CROPPED_LOGO from '../../assets/imgs/TDFLogo.png';
import TDF_LOGO from '../../assets/imgs/DUE-FACTORY.png';
import QUICK_TIP_IMG from '../../assets/img/SidebarQuickTips.png';
import { MdOutlineKeyboardArrowRight, MdSettingsSuggest } from 'react-icons/md';
import { toggleSidebar, setScreenSize } from "../../store/store";
import { useSelector, useDispatch } from "react-redux";
import { FaRegThumbsUp } from 'react-icons/fa';
import { RiFileCopy2Line } from 'react-icons/ri';
import { useLocation, useNavigate } from 'react-router-dom';
import { TbFileDescription } from 'react-icons/tb';
import { BiSolidOffer } from 'react-icons/bi';
const SidebarScreen = () => {
    const [innerWidth, setinnerWidth] = useState(0);
    const location = useLocation();
    const navigate = useNavigate();
    const isOpen = useSelector((state) => state.sidebar.isOpen);
    const isHalfOpen = useSelector((state) => state.sidebar.isHalfOpen);
    const dispatch = useDispatch();
    const { sidebarWidth } = useSelector((state) => state.sidebar);
    useEffect(() => {
        const handleResize = () => {
            setinnerWidth(window.innerWidth);
            dispatch(setScreenSize(window.innerWidth));
        };

        handleResize(); // Check on mount
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const checkRequired = () => {
        return (innerWidth >= 900 && isHalfOpen) ? TDF_CROPPED_LOGO : TDF_LOGO;
    }
    const checkMargin = () => {
        const WIDTH = sidebarWidth;
        if (isHalfOpen) {
            return WIDTH + 10
        } else {
            return WIDTH - 15
        }
    }

    const SidebarListElements = [
        { key: "dash-credit-report", title: "Credit Report", path: "/home/dashboard", icon: <RiFileCopy2Line /> },
        { key: "dash-credit-report-1", title: "Credit Insights", path: "/home/dashboard-2", icon: <TbFileDescription /> },
        { key: "dash-credit-report-2", title: "Recommendations  ", path: "/home/dashboard-3", icon: <MdSettingsSuggest /> },
        { key: "dash-credit-report-4", title: "Settlements", path: "/home/dashboard-4", icon: <BiSolidOffer /> }
    ]

    const goToNav = (path) => {
        navigate(path)
    }


    const checkActivePath = (screen) => {
        const str = location.pathname.split("/");
        const path = screen.split("/")
        if (
            str[0] === path[0] &&
            str[1] === path[1] &&
            str[2] === path[2]) {
            return true;
        } else {
            return false;
        }

    }
    return (
        <>
            <div style={{
                display:
                    innerWidth == 900
                        ?
                        (isOpen ? "block" : "none")
                        :
                        (isOpen || isHalfOpen ? "block" : "none"),
                width: sidebarWidth,
            }} className="home-dash-sidebar">
                <span className='home-dash-sidebar-btn'
                    style={{
                        marginLeft: checkMargin(),
                        display: innerWidth > 900 ? "flex" : "none",
                        transform: `rotate(${isHalfOpen ? 0 : -180}deg)`,
                        background: isHalfOpen ? "#00b8ff29" : "",
                    }}
                    onClick={() => dispatch(toggleSidebar())}
                >
                    <MdOutlineKeyboardArrowRight />
                </span>
                <div className={`home-dash-sidebar-inner`}>
                    <div className='home-dash-sidebar-head'>
                        <img src={checkRequired()} alt="logo" />
                    </div>
                    <div className='home-dash-sidebar-body'>
                        <div
                            style={{
                                padding: isHalfOpen ? "0px" : "5px"
                            }}
                            className='home-dash-sidebar-body-inner'>
                            {SidebarListElements.map((item) =>
                                <div
                                    key={item.key}
                                    onClick={() => goToNav(item.path)}
                                    style={{
                                        width: isHalfOpen ? "auto" : ""
                                    }}
                                    className={
                                        checkActivePath(item.path)
                                            ?
                                            "active-elem"
                                            :
                                            ""
                                    }
                                >
                                    {item.icon}
                                    {
                                        !isHalfOpen ? <span>{item.title}</span> : <></>
                                    }
                                    {
                                        isHalfOpen ? <span className='hover-tooltip'>{item.title}</span> : <></>
                                    }

                                </div>

                            )}
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex", // Always rendered in the DOM
                            transition: "opacity .3s ease-in-out, visibility .3s ease-in-out",
                            opacity: !isHalfOpen ? 1 : 0,
                            visibility: !isHalfOpen ? "visible" : "hidden",
                            height: 90
                        }}
                        className='home-dash-sidebar-footer'>
                        <div className='home-dash-sidebar-footer-top'>
                            <FaRegThumbsUp style={{ margin: 5 }} />
                            <span>Quick Tip</span>
                        </div>
                        <div className='home-dash-sidebar-footer-bottom'>
                            <p>Opening a new credit accounts can sometimes leads to lower credit score</p>
                            <img src={QUICK_TIP_IMG} alt='img-quick-tip' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SidebarScreen