import axiosInstance from "./axiosInstance";  // Import the configured axios instance

const apiClient = {
  // GET method
  get: async (url, config = {}) => {
    try {
      const response = await axiosInstance.get(url, config);
      return response;  // Return the data directly
    } catch (error) {
      console.error("GET request failed:", error);
      throw error;
    }
  },

  // POST method
  post: async (url, data, config = {}) => {
    try {
      const response = await axiosInstance.post(url, data, config);
      return response;  // Return the data directly
    } catch (error) {
      console.error("POST request failed:", error);
      throw error;
    }
  },

  // PUT method
  put: async (url, data, config = {}) => {
    try {
      const response = await axiosInstance.put(url, data, config);
      return response;  // Return the data directly
    } catch (error) {
      console.error("PUT request failed:", error);
      throw error;
    }
  },

  // DELETE method
  delete: async (url, config = {}) => {
    try {
      const response = await axiosInstance.delete(url, config);
      return response;  // Return the data directly
    } catch (error) {
      console.error("DELETE request failed:", error);
      throw error;
    }
  },
};

export default apiClient;
