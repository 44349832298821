import React from 'react';
import "./style.css";
import ScoreGauge from '../../CentralMenu/ScoreGauge';
const CommonLandScreen = () => {
    return (
        <div className='landing-page-common'>
            <ScoreGauge score={803} />
            <p>
                Congratulations Gaurav, your credit score of 803 falls within the
                'Excellent' range, showcasing your responsible credit behavior.
                This is complemented by a good mix of credit types and an impressive
                payment history. However, your high credit utilization rate of
                95.6% may be a concern as it could negatively impact your score,
                suggesting that you may need to focus on lowering your outstanding
                balances.
            </p>
        </div>
    )
}
const LandingDashboard = () => {
    return (
        <div className='landing-page-main'>
            <CommonLandScreen />
        </div>
    )
}

export default LandingDashboard