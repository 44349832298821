import React, { useEffect, useRef, useState } from 'react';
import './style.css';
import exp_img from "../assets/imgs/Experian_logo.svg"
const ScoreGauge = ({ score = 800 }) => {
    const needleRef = useRef(null);
    const [animatedScore, setAnimatedScore] = useState(300); // Start from 300
    const minScore = 300;
    const maxScore = 900;
    const minAngle = -124;
    const maxAngle = 124;

    const calculateNeedleAngle = (score) => {
        const scorePercentage = (score - minScore) / (maxScore - minScore);
        return minAngle + scorePercentage * (maxAngle - minAngle);
    };

    const needleAngle = calculateNeedleAngle(score);

    useEffect(() => {
        // Animate the needle
        if (needleRef.current) {
            needleRef.current.style.transform = `translate(0%, -50%) rotate(${needleAngle}deg)`;
        }

        // Animate the score
        let currentScore = 300; // Starting point
        const interval = setInterval(() => {
            if (currentScore < score) {
                currentScore += 10; // Increment by 5
                setAnimatedScore(Math.min(currentScore, score)); // Cap at the target score
            } else {
                clearInterval(interval); // Stop animation when target is reached
            }
        }, 20); // 20ms interval for smooth animation

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [score, needleAngle]);
    const checkColour = (Sc) => {
        if (Sc >= 300 && Sc < 700) {
            return `#f51111`;
        } else if (Sc >= 700 && Sc < 800) {
            return `#6dbc5d`;
        } else {
            return `#12a547`
        }
    }

    return (
        <>

            <div className="new-score-gauge-main">
                <div className="new-score-gauge">
                    <div className="score-gauge-arc-1"></div>
                    {/* <div className="score-gauge-arc-2"></div>
                    <div className="score-gauge-arc-3"></div>
                    <div className="score-gauge-arc-4"></div> */}
                    <div className="new-score-gauge-circle">
                        <div
                            ref={needleRef}
                            className="triangle-shape-needle"
                        ></div>
                        <div className="new-score-gauge-circle-1">
                            <div className="new-score-gauge-circle-2">
                                <span>{animatedScore}</span> {/* Display the animated score */}
                                <div style={{
                                    color: checkColour(animatedScore)
                                }}>
                                    {score >= 800 ? 'Excellent' :
                                        score >= 700 ? 'Good' :
                                            score >= 600 ? 'Fair' : 'Bad'}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="score-gauge-milstone-1">300</div>
                    <div className="score-gauge-milstone-2">600</div>
                    <div className="score-gauge-milstone-3">700</div>
                    <div className="score-gauge-milstone-4">800</div>
                    <div className="score-gauge-milstone-5">900</div>
                </div>
                <div>
                    <p>Powered By </p>
                    <img src={exp_img} alt='exp-img' />
                </div>
            </div>
        </>
    );


};

export default ScoreGauge;
