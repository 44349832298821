import {
  Box,
  Button,
  Popover,
  Typography,
  Grid,
  TextField,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import TDFGirl from "../assets/imgs/TDFGirl.webp";
import Score702 from "../assets/imgs/mobile-hero-1.png";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../service/axiosInstance";
import bgimg from "../assets/imgs/banner-bg.jpg";
import TDFMobile from "../assets/homePage2/TDFphone.webp";
import HeaderComponent from "../Components/HeaderCoponent";
import "./style.css"
import { profileApiData } from "../service/ApiList";
function HeroSection() {
  const isMobile = useMediaQuery("(max-width:600px)");
  
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const utm = searchParams.get("utm");

  const utm_source = searchParams.get("utm_source");
  const utm_medium = searchParams.get("utm_medium");
  const utm_campaign = searchParams.get("utm_campaign");
  const utm_content = searchParams.get("utm_content");

  if (utm_content || utm_medium || utm_campaign || utm_content) {
    localStorage.setItem("utm_source", utm_source);
    localStorage.setItem("utm_medium", utm_medium);
    localStorage.setItem("utm_campaign", utm_campaign);
    localStorage.setItem("utm_content", utm_content);
  }




  const token = localStorage.getItem("TDFToken");
  const loginConfirm = localStorage.getItem("loginConfirm");

  const [formData, setFormData] = useState({
    mobile: "",
  });
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (path) => {
    navigate(path);
    handleClose();
  };
  const validateIndianMobile = (number) => {
    const indianMobileRegex = /^[6-9]\d{0,9}$/; 
    return indianMobileRegex.test(number);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if(validateIndianMobile(value) || value === "" ){
      setFormData({
        ...formData,
        [name]: value,
      });
    } 

  };
  const handleKeyPress = (e) => { 
    if (e.key === "Enter" && formData.mobile.length === 10) {
      handleSubmit();
    }
    else if (e.key === "Enter" ) {
      toast.error("Please enter a valid 10-digit phone number.");
    }
  };
  const handleSubmit = () => {
   
    if (formData.mobile.length === 10) {
      navigate("/login", { state: { mobile: formData.mobile } });
    } else {
      toast.error("Please enter a valid 10-digit phone number.");
    }
  };
  useEffect(() => {


    if (utm || id) {
      axiosInstance
        .get(`/tdf/api/campaign/getCampaignUrl?${id ? "id" : "utm"}=${id ? id : utm}`)
        .then((res) => {
          const fullUrl = res?.data?.response;

          if (fullUrl) {
            // Extract the query string part
            const urlObj = new URL(fullUrl);
            const queryString = urlObj.search.slice(1); // Removes the "?" at the beginning

            // Store the query string in localStorage
            localStorage.setItem("utmCampaign", queryString);
          }
        })
        .catch((err) => {
          console.error("Error fetching campaign URL:", err);
        });
    }
  }, [id, utm]);


  const gotoLogin = () => {
    navigate("/login");
  };

  const handleLogin = () => {
    if (token ) {
      localStorage.clear("experianData");
      localStorage.clear("TDFToken");
      navigate("/");
    } else {
      navigate("/login");
    }
  };
  // const [bgPositionY, setBgPositionY] = useState(0);

  // // Handle Scroll Event
  // const handleScroll = () => {
  //   const scrollY = window.scrollY;
  //   setBgPositionY(scrollY * 0.5); // Adjust the multiplier to control speed
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

   

  return (
    <>
      <Box
        sx={{
          // background: "linear-gradient(to right, #044A67, #0a6b90)",
          backgroundImage: `url(${bgimg})`,
          backgroundSize: "cover", // Ensures the image covers the entire element
          backgroundPosition: "-90px 0", // Centers the image in the element
          width: "100%",
          // minHeight: "minHeight",
          minHeight: { xs: "auto", sm: "100vh", lg: "100vh", md: "100vh" },
          display: "flex",
          flexDirection: "column",
          justifyContent:"end"
        }}
      >

        <HeaderComponent />
        {/* Header Section End */}
        <Box
          sx={{
            padding: { xs: "none", sm: "none", lg: "none" },
            marginTop: { xs: "90px", sm: "90px", lg: "0px", md: "0px" },
          }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              lg={6}
              md={6}
              sx={{
                display: { xs: "flex", sm: "flex", lg: "flex", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  // paddingLeft: {
                  //   xs: "15px",
                  //   sm: "40px",
                  //   md: "none",
                  //   lg: "0",
                  // },
                  width: { xs: "85%", sm: "85%", md: "100%", lg: "100%" },
                  // paddingRight: {
                  //   xs: "15px",
                  //   sm: "none",
                  //   md: "none",
                  //   lg: "0",
                  // },
                  paddingTop: {
                    xs: "none",
                    sm: "none",
                    md: "none",
                    lg: "0",
                  },

                  marginTop: { xs: "none", sm: "0", lg: "none" },
                  padding: {
                    xs: "none",
                    sm: "none",
                    md: "0 0 0 90px",
                    lg: "0 40px 0 90px",
                  },

                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "35px", sm: "44px", lg: "64px", xl:'64px' },
                    color: "#fff",
                    textAlign: { xs: "center", sm: "start", lg: "start" },
                    fontFamily: "CustomFontMedium",
                    fontWeight: {
                      sm: "normal",
                      lg: "normal",
                      md: "normal",
                      xs: "500",
                    },

                    lineHeight: { xs: "normal", sm: "normal", lg: "58px",xl:"77px" },
                  }}
                >
                  Pull your credit report, free of cost
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "16px", sm: "18px", lg: "18px", xl:"26px" },
                    color: "#fff",
                    textAlign: { xs: "center", sm: "start", lg: "start" },
                    padding: { xs: "10px", sm: "none", lg: "none" },
                    marginTop: { xs: "16px", sm: "20px", lg: "20px" },
                    fontFamily: "CustomFontMedium",
                    fontWeight: "400",
                    paddingRight: { xs: "none", lg: "30px" },
                  }}
                >
                  Join us to get deep insights on your credit health. Free
                  bureau score, analysis, recommendation and support.
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    marginTop: "20px",
                    justifyContent: {
                      xs: "center",
                      sm: "center",
                      md: "normal",
                      md: "normal",
                    },
                  }}
                >
                  <TextField
                    id="phone-input"
                    variant="outlined"
                    size="large"
                    placeholder="Enter Phone Number"
                    name="mobile"
                    type="number"
                    value={formData.mobile}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                    InputProps={{
                      sx: {
                        color: "white", // Text color inside the input
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "white",
                          background: "#ffffff1f"

                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "white", // Keep border white on hover
                        },
                      },
                      style: {
                        borderRadius: "8px", // Add border-radius here as well
                      },
                    }}
                    inputProps={{
                      sx: {
                        "&::placeholder": {
                          fontSize: { xs: "12px", sm: "16px", }, // Placeholder font size based on screen size
                          textAlign: { xs: "center", sm: "inherit" },
                          opacity: 1,

                        },
                        padding: { xs: "8px", sm: "14px" }
                      },
                      style: {
                        color: "#fff", // Placeholder color
                      },
                    }}

                    InputLabelProps={{
                      style: {
                        color: "white",
                      },
                    }}
                    sx={{
                      width: { xs: "100%", sm: "40%", md: "40%" },

                    }}
                  />
                  <Button
                    sx={{
                      "&:hover": {
                        background: "#f0f0f0", // Change this to the color you want on hover
                        color: "#333", // Optional: Change text color on hover
                      },
                      textTransform: "capitalize",
                      background: "#fff",
                      fontFamily: "CustomFontMedium",
                      // fontWeight: "600",
                      color: "black",
                      // fontSize: { xs: "", sm: "", md: "18px", lg: "18px" },
                      width: {
                        xs: "100%",
                        sm: "40%",
                        md: "48%",
                        lg: "51%",
                        xl: "45%",
                      },
                      marginLeft: {
                        xs: "5px",
                        sm: "10px",
                        md: "none",
                        lg: "15px",
                      },
                      fontSize: {
                        xs: "12px",
                        // sm: "10px",
                        // md: "none",
                        lg: "16px",
                        xl: "22px",
                      },
                      borderRadius: "10px",
                      fontWeight: {
                        xs: "600",
                        sm: "600",
                        md: "600",
                        lg: "500",
                      },
                      whiteSpace:"pre"
                    }}
                    onClick={handleSubmit}
                  >
                    {isMobile ? "Get Your Report" : "Get Your Credit Report"}
                  </Button>
                </Box>
                <Typography
                  sx={{
                    fontSize: { xs: "16px", sm: "18px", lg: "18px",xl:"26px" },
                    color: "#fff",
                    textAlign: { xs: "center", sm: "start", lg: "start" },
                    padding: { xs: "10px", sm: "none", lg: "none" },
                    marginTop: { xs: "16px", sm: "20px", lg: "20px" },
                    fontFamily: "CustomFontMedium",
                    fontWeight: "400",
                  }}
                >
                  We take care of your credit health every step of the way.
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={0}
              sm={2}
              lg={2}
              md={2}
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  lg: "flex",
                  // md:"block"
                },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box>
                <img src={TDFMobile} alt="tdfPhone" max-width="300px" />
                <Box
                  sx={{
                    display: "inline-block",
                    textAlign: "center",
                    position: "absolute",
                    marginTop: "200px",
                    marginLeft: "-190px",
                  }}
                >
                  <span
                    style={{
                      color: "#fff",
                      fontSize: "12px",
                      fontFamily: "CustomFontMedium",
                    }}
                  >
                    Your Score
                  </span>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "54px",
                      fontFamily: "CustomFontMedium",
                      fontWeight: "600",
                    }}
                  >
                    657
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              md={6}
              sx={{
                display: { xs: "none", sm: "none", lg: "flex", md: "flex" },
                height: "90vh",
                // overflow:"hidden"
                justifyContent: "center",
                marginTop: "25px",
              }}
            >
              <Box  className="tdf-hero-secton-div-1">
                <img
                  src={TDFGirl}
                  alt="TDFGirl"
                  // width="auto"
                  // height="auto"
                  className="tdf-girl-hero-section"
                // style={{ position: "relative", top: "5px" }}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={0}
              lg={0}
              sx={{
                display: { xs: "flex", sm: "flex", lg: "none", md: "none", justifyContent: "center" },
              }}
            >
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={Score702}
                  alt="score"
                  width="60%"
                  style={{ marginTop: "40px", marginLeft: "5px" }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default HeroSection;
