import React, { useState } from "react";
import HeaderCoponent from "../../Components/HeaderCoponent";
import "./GrievanceRedressal.css";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FotterComponent from "../../Components/FotterComponent";

function GrievanceRedressal() {
  const [expanded, setExpanded] = useState("panel1");

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false); // Only the clicked panel will stay expanded
  };
  const grievanceData = [
    {
      heading: "1. Objectives",
      answer: (
        <>
          The Company understands that despite striving to achieve high
          standards of customer service excellence, there can be gaps in
          existing service delivery. Hence, it is critical to provide an
          appropriate system and process to customers to share their Grievances.
          The Company aspires to create robust systems and processes to ensure
          effective and timely solutions to Grievances with an impartial
          approach to set higher benchmarks towards effective stakeholder
          engagement. We have ensured that all employees of the company are
          aware and well versed with our Customer Grievance Redressal Mechanism
          (“Mechanism”). Our Mechanism is based on following principles.
          <ul>
            <li>
              Customers are always treated equitably without any prejudice at
              all instances
            </li>
            <li>
              Grievance raised by customers are acknowledged attentively and
              dealt with courtesy in a timely manner and address the key
              questions on why the complainant feels aggrieved or dissatisfied
            </li>
            <li>
              Customers are provided with multiple touchpoints to submit their
              Grievances
            </li>
            <li>
              Customers are facilitated and sensitized about their rights in the
              Company and mechanism to convey their Grievances as well as their
              rights pertaining to alternate remedies in the Company if they are
              not satisfied with the resolution of their Grievances
            </li>
            <li>
              Grievances are investigated and redressed in a time bound and
              transparent manner
            </li>
            <li>
              Grievances are reviewed and critically analyzed to improve the
              existing processes and systems to ensure that the re-occurrence of
              such Grievances are minimized
            </li>
          </ul>
          On the basis of this Mechanism, the Company shall ensure that an
          appropriate Mechanism subsists for the purpose of receiving and
          addressing Grievance from its customers, with the aim of resolving the
          Grievance equitably and expeditiously.
          <p style={{ marginTop: "5px" }}>
            The Mechanism encompasses adherence to the provisions of Digital
            Lending guidelines and other regulations stipulated by Reserved Bank
            of India (RBI).
          </p>
        </>
      ),
    },
    {
      heading: "2. Grievance Redressal Framework",
      answer: (
        <>
          <div style={{ paddingLeft: "10px" }}>
            <b>1. How to lodge a Grievance/where can a Grievance be made?</b>
            <p>
              Means and Mode- Any customer having a Grievance with respect to
              the product and services offered by the Company may reach out to
              the Company through any of the following channels:
            </p>
            <ul>
              <li> Email at: care@theduefactory.com</li>
              <li> message at: www.duefactory.com/contact-us</li>
              <li>
                {" "}
                Write a letter at the address: 806-807, Udyog Vihar Phase V,
                Sector 19, Gurugram, Haryana – 122016
              </li>
            </ul>
            <b>Format of Grievance:</b>
            <p>
              Customers are requested to necessarily provide necessary details
              which includes:
            </p>
            <ul>
              <li>Details of Products for which Grievance is made</li>
              <li>Date of Application submitted</li>
              <li>Details of Grievance</li>
              <li>
                Valid Contact Information including Phone No. & E-mail ID
                registered with the Company at the time of registration for
                availing the services of Due Factory.
              </li>
            </ul>

            <b>2. How will we address a Grievance?</b>
            <p style={{ marginTop: "5px" }}>
              a. When the customer Grievance is received, we will endeavour to
              send an acknowledgement/a response within 48 working hours of
              receiving the Grievance.
            </p>
            <p>
              b. After examining the matter, we will send our final response or
              explain why we need more time to respond and shall endeavor to do
              so within 30 days of receipt of Grievance.
            </p>
            <p>
              c. Grievance raised by mutual funds customers over the SCORES
              platform of SEBI must be redressed as soon as possible, but no
              later than 21 calendar days after the complaint was received.
            </p>
            <br />
            <b>3. Details of Nodal Grievance Redressal Officer</b>
            <p style={{ marginTop: "5px" }}>Name of the Officer - Grievance Officer</p>
            <p>Call at - 9205657843- (Lines are open Mon-Fri from 10 AM to 5 PM)</p>          
            <div className="office-add-tdf">
              <p style={{ whiteSpace: "pre" }}>Office Address - </p>{" "}
              <b style={{ marginLeft: "5px" }}>
                806-807, Udyog Vihar Phase V, Sector 19, Gurugram, Haryana –
                122016
              </b>
            </div>
            <div style={{ display: "flex" }}>
              <p>E-mail -</p>{" "}
              <b style={{ marginLeft: "5px" }}>care@theduefactory.com</b>
            </div>
          </div>
        </>
      ),
    },
    {
      heading: "3. Continuous Feedback from Customers",
      answer:
        "The Company will also solicit voluntary feedback from customers on an ongoing basis in a bid to improve its customer service quality.",
    },
    {
      heading: "4. General",
      answer:
        "Notwithstanding anything contained in this Mechanism, the Company shall ensure compliance with any additional requirements as may be prescribed under any laws/regulations either existing or arising out of any amendment to such laws/regulations or otherwise and applicable to the Company from time to time.",
    },
    {
      heading: "5. Review",
      answer: (
        <>
          <p>
            a. This Mechanism is subject to review by the Company as and when
            necessary.
          </p>
          <p style={{ marginTop: "5px" }}>
            b. This Mechanism shall be subject to the applicable laws including
            but not limited to the rules, regulations, guidelines, directives
            and instructions issued by RBI and any other statutory authority
            from time to time and shall supersede the earlier version of the
            Mechanism. Any change/amendment in applicable laws with regard to
            maintenance of an appropriate Grievance Redressal Mechanism shall be
            deemed to be incorporated in this Mechanism by reference and this
            Mechanism shall be deemed to have been amended and revised
            accordingly
          </p>
          <br />
          <b>Modified on : 31 Dec. 2024</b>
        </>
      ),
    },
  ];
  return (
    <>
      <div className="page-container-grievance-1 ">
        <div className="content-container-grievance-1">
          <div>
            <HeaderCoponent />

            <div className="grievance-div-bg">
              <div className="grievance-div-1">
                Grievance Redressal Mechanism
              </div>
              <div className="grievance-div-text">
                <span className="grievance-highlight-text">
                  Due Factory Services Private Limited
                </span>{" "}
                (hereinafter referred to as the{" "}
                <span className="grievance-highlight-text">'Company'</span>)
                believes that customer service is its highest priority as that
                is what drives growth and continuous business. The Company
                believes that prompt, efficient, and transparent service is
                critical to build long lasting customer relationships. The
                Company is determined to ensure expeditious and efficacious
                handling of the customer Grievances, as well as prompt remedial
                & preventive action (inclusive of amendment of the Mechanism, as
                and when required) with the objective to avert repetitiveness.
              </div>
              <div className="grievance-faq-sub">
                <div className="grievance-faq-sub-1">
                  {grievanceData.map((faq, index) => (
                    <Accordion
                      key={index}
                      expanded={expanded === `panel${index + 1}`}
                      onChange={handleAccordionChange(`panel${index + 1}`)}
                      style={{
                        boxShadow: "none",
                        position: "unset",
                        marginTop: "10px",
                        fontFamily: "Nunito Sans",
                      }}
                      //   class not working so write inline css
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index + 1}-content`}
                        id={`panel${index + 1}-header`}
                        style={{
                          borderBottom: "0.5px solid rgb(206 206 206)",
                          fontSize: "14px",
                          fontWeight: "700",
                        }}
                      >
                        {faq.heading}
                      </AccordionSummary>
                      <AccordionDetails
                        style={{
                          borderBottom: "0.5px solid rgb(206 206 206)",
                          fontSize: "14px",
                          fontWeight: "500",
                          padding: "20px 16px 20px 16px",
                        }}
                      >
                        {faq.answer}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-grid-grievance-1">
          <FotterComponent />
        </div>
      </div>
    </>
  );
}

export default GrievanceRedressal;
