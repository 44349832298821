import { API, PATHS } from "./ApiSupport";

export function GET_INSIGHTS(params, cb) {
  const payload = {}; 
  API.POST(PATHS.GET_INSIGHTS, payload, params, (res, err) => {
    if (err) {
      cb(null, err); 
    } else {
      cb(res, null); 
    }
  });
}
export function USER_REPORT(cb) {
  API.GET(PATHS.USER_GET_REPORT, {}, {}, function (res) {
    cb(res);
  });

    // test case
    // const promiseNew = new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     resolve( {           
    //       "status":200,
    //       "data" : 
    //         {
    //       "customerDataResponse": null,
    //       "myReport": null,
    //       "data": false,
    //       "experian": false,
    //       "error": null,
    //       "openForm": true
    //   }}
    // );
    //   }, 1000);
    // });
    // promiseNew.then((resolvedValue) => {
    //   cb(resolvedValue);  
    // });
  // test case

}
export function GENERATEOTP(id, cb) {
  const payload = {};
  API.POST(PATHS.GET_OTP(id), {}, {}, function (res) {
    cb(res);
  });
   // test case
  //  const promiseNew = new Promise((resolve, reject) => {
  //   setTimeout(() => {
  //     resolve( {
  //       "data": "OTP Sent",
  //       "status": 200,
  //       "statusText": "",
  //       "headers": {
  //           "cache-control": "no-cache, no-store, max-age=0, must-revalidate",
  //           "content-length": "8",
  //           "content-type": "application/json",
  //           "expires": "0",
  //           "pragma": "no-cache"
  //       },
  //       "config": {
  //           "transitional": {
  //               "silentJSONParsing": true,
  //               "forcedJSONParsing": true,
  //               "clarifyTimeoutError": false
  //           },
  //           "adapter": [
  //               "xhr",
  //               "http",
  //               "fetch"
  //           ],
  //           "transformRequest": [
  //               null
  //           ],
  //           "transformResponse": [
  //               null
  //           ],
  //           "timeout": 0,
  //           "xsrfCookieName": "XSRF-TOKEN",
  //           "xsrfHeaderName": "X-XSRF-TOKEN",
  //           "maxContentLength": -1,
  //           "maxBodyLength": -1,
  //           "env": {},
  //           "headers": {
  //               "Accept": "application/json, text/plain, */*",
  //               "Content-Type": "application/json"
  //           },
  //           "baseURL": "https://uat.duefactory.com",
  //           "params": {},
  //           "method": "post",
  //           "url": "/tdf/api/generate/otp/8630040546",
  //           "data": "{}"
  //       },
  //       "request": {}
  //   }
  // );
  //   }, 1000);
  // });
  // promiseNew.then((resolvedValue) => {
  //   cb(resolvedValue);  
  // });
// test case
}
export function contactUsApiData(params, cb) {
  API.POST(PATHS.POST_CONTACT_MSG, params, {}, (res, err) => {
    if (err) {
      cb(null, err); 
    } else {
      cb(res, null); 
    }
  });
}
export function POST_REVOKECONSENT(params, cb) {
  const payload = {}; 
  API.POST(PATHS.POST_REVOKECONSENT, payload, params, (res, err) => {
    if (err) {
      cb(null, err);
    } else {
      cb(res, null); 
    }
  });
}
export function AUTHTOKEN(params, cb) {
  API.POST(PATHS.POST_AUTH_TOKEN, params, {}, (res, err) => {
    if (err) {
      cb(null, err); 
    } else {
      cb(res, null);
    }
  });
  // test case
  //   const promiseNew = new Promise((resolve, reject) => {
  //   setTimeout(() => {
  //     resolve( { "data" :
  //       {
  //         "access_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsiYXBpIl0sInVzZXJfbmFtZSI6Ijg2MzAwNDA1NDYiLCJzY29wZSI6WyJyZWFkIiwid3JpdGUiXSwiZXhwIjoxNzM2MzYxOTY0LCJhdXRob3JpdGllcyI6WyJDVVNUT01FUiIsIkJPUlJPV0VSIl0sImp0aSI6IjU0ZDVhNmE2LTU4ZTAtNGJmNy1iMDc0LTdiZjM3YjZlNTc2OCIsImNsaWVudF9pZCI6ImR1ZUZhY3RvcnkifQ.9ri89jNxgTvRgv0PIYEzdxBndfsRLZ8oRAZcC0iNKSk",
  //         "token_type": "bearer",
  //         "refresh_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsiYXBpIl0sInVzZXJfbmFtZSI6Ijg2MzAwNDA1NDYiLCJzY29wZSI6WyJyZWFkIiwid3JpdGUiXSwiYXRpIjoiNTRkNWE2YTYtNThlMC00YmY3LWIwNzQtN2JmMzdiNmU1NzY4IiwiZXhwIjoxNzM2NzUwNzY0LCJhdXRob3JpdGllcyI6WyJDVVNUT01FUiIsIkJPUlJPV0VSIl0sImp0aSI6ImJlMDliNjQzLWViNWItNDc0My04MTk2LTRlMGNjZTAxN2QyNiIsImNsaWVudF9pZCI6ImR1ZUZhY3RvcnkifQ.QC_izetWCt2LWe0eHaoOhfUF7ZxZKjnifQ9LLycmFP8",
  //         "expires_in": "43200",
  //         "scope": "read write",
  //         "jti": "54d5a6a6-58e0-4bf7-b074-7bf37b6e5768"
  //     }
  
  // }
  // );
  //   }, 1000);
  // });
  // promiseNew.then((resolvedValue) => {
  //   cb(resolvedValue);  
  // });
// test case
}
export function saveUserDataApi(params, cb) {
  const payload = {}; 
  API.POST(PATHS.POST_SAVE_USER_TRACK, payload, params, (res, err) => {
    if (err) {
      cb(null, err); 
    } else {
      cb(res, null); 
    }
  });
}
export function callBackApi(params, cb) {
  API.POST(PATHS.POST_CALL_ME_BACK, params, {}, (res, err) => {
    if (err) {
      cb(null, err);
    } else {
      cb(res, null);
    }
  });
}
export function getPaymentlinkdata(params, cb) {
  API.POST(PATHS.POST_GETING_PAYMENT_LINK, params, {}, (res, err) => {
    if (err) {
      cb(null, err);
    } else {
      cb(res, null); 
    }
  });
}
export function counterOffterData(params, cb) {
  API.POST(PATHS.POST_COUNTER_OFFER, params, {}, (res, err) => {
    if (err) {
      cb(null, err); 
    } else {
      cb(res, null);
    }
  });
}
export function settlementListingApiData(params, cb) {
  API.POST(PATHS.POST_SETTLEMENT_LISTING, params, {}, (res, err) => {
    if (err) {
      cb(null, err); 
    } else {
      cb(res, null);
    }
  });
}
export function profileApiData(params, cb) {
  API.GET(PATHS.GET_PROFILE_DETAIL, params, {}, (res, err) => {
    if (err) {
      cb(null, err); 
    } else {
      cb(res, null);
    }
  });

  // test case start
  //     const promiseNew = new Promise((resolve, reject) => {
  //   setTimeout(() => {
  //     resolve( { 
  //        "status": 200,
  //       // "data" :   
  //       //    {
  //       //       "id": null,
  //       //       "createdDate": null,
  //       //       "lastUpdated": null,
  //       //       "name": null,
  //       //       "surName": null,
  //       //       "mobile": null,
  //       //       "pan": null,
  //       //       "dob": null,
  //       //       "pinCode": null,
  //       //       "address": null,
  //       //       "state": null,
  //       //       "country": null,
  //       //       "city": null,
  //       //       "creditScore": null,
  //       //       "email": null,
  //       //       "gender": null,
  //       //       "status": null,
  //       //       "lastAction": null,
  //       //       "utmFlag": null,
  //       //       "utmSource": null,
  //       //       "utmMedium": null,
  //       //       "utmCampaign": null,
  //       //       "utmContent": null,
  //       //       "dispositionFlag": null,
  //       //       "dispositionType": null,
  //       //       "dispositionValue": null,
  //       //       "dispositionComment": null,
  //       //       "dispositionDate": null,
  //       //       "adminAgentEmail": null,
  //       //       "adminAllocationDate": null,
  //       //       "teamLeadAgentEmail": null,
  //       //       "teamLeadAllocationId": null,
  //       //       "teamLeadAllocationDate": null,
  //       //       "teleCallerAgentEmail": null,
  //       //       "teleCallerAllocationId": null,
  //       //       "teleCallerAllocationDate": null,
  //       //       "whatsAppConsentFlag": null,
  //       //       "experianConsentFlag": null,
  //       //       "conversionFlag": null,
  //       //       "bureauFetchAt": null,
  //       //       "bureauFetchFlag": null,
  //       //       "bureauDeletedAt": null,
  //       //       "badAccounts": null,
  //       //       "lastLogin": null,
  //       //       "employmentType": null,
  //       //       "monthlyIncome": null,
  //       //       "profileCompletedFlag": null,
  //       //       "redirectionUrl": null,
  //       //       "revokeConsent": null,
  //       //       "revokeConsentAt": null
  //       //   }
  //       "data":{
  //         "id": "66ebcccd3a95fe336fab7ef7",
  //         "createdDate": 1726729421937,
  //         "lastUpdated": 1735625693037,
  //         "name": "Rahul singh",
  //         "surName": "kushwah",
  //         "mobile": "8630040546",
  //         "pan": "IUSPS2355L",
  //         "dob": "08-Nov-2024",
  //         "pinCode": 283201,
  //         "address": "agra",
  //         "state": "09",
  //         "country": null,
  //         "city": "agra",
  //         "creditScore": 727,
  //         "email": "Rahulkushwah8057@gmail.com",
  //         "gender": 1,
  //         "status": "REVOKE_CONSENT",
  //         "lastAction": "CALL_ME_BACK",
  //         "utmFlag": false,
  //         "utmSource": null,
  //         "utmMedium": null,
  //         "utmCampaign": null,
  //         "utmContent": null,
  //         "dispositionFlag": false,
  //         "dispositionType": null,
  //         "dispositionValue": null,
  //         "dispositionComment": null,
  //         "dispositionDate": null,
  //         "adminAgentEmail": null,
  //         "adminAllocationDate": null,
  //         "teamLeadAgentEmail": null,
  //         "teamLeadAllocationId": null,
  //         "teamLeadAllocationDate": null,
  //         "teleCallerAgentEmail": null,
  //         "teleCallerAllocationId": null,
  //         "teleCallerAllocationDate": null,
  //         "whatsAppConsentFlag": true,
  //         "experianConsentFlag": true,
  //         "conversionFlag": false,
  //         "bureauFetchAt": 1735206802509,
  //         "bureauFetchFlag": true,
  //         "bureauDeletedAt": null,
  //         "badAccounts": [],
  //         "lastLogin": 1735625693037,
  //         "employmentType": "SALARIED",
  //         "monthlyIncome": 1.0E10,
  //         "profileCompletedFlag": true,
  //         "redirectionUrl": "https://dev-customer.easycardsloans.com/credit-card/apply/?utm_org_code=ORG01034&encryptedData=UkfdyMZHfIczsnY1bsYlnxKEhFdeftouZPynwi6XQiYeZCwrQaRFIDStNM/QN5n/CDMFQd75Wk8WqCZIRLVpmPrrr0q26MJ+NewEjhLj1ZWIiRNh8TY/6tjdkqbWR8D9r2XnrIC1tvY83KHrxUQ/tAqJiJDkOb/z/9RLUPKTzDyCVfVxf3oZgKSg0VAfE9nlai6zebFSMhfXbx2jWAM3W1hpOB5GpVEwLo+M4RlRxCU8JFHZXyUxdbU43KHsUKUALZJTkjoDC2apvE3nMXE23w==",
  //         "revokeConsent": null,
  //         "revokeConsentAt": null
  //     }
  //       }
  // );
  //   }, 100);
  // });
  // promiseNew.then((resolvedValue) => {
  //   cb(resolvedValue);  
  // });


 
}
export function fetchReportData(params, cb) {
  API.POST(PATHS.POST_ENHANCED_FETCH_REPORT, params, {}, (res, err) => {
    if (err) {
      cb(null, err);
    } else {
      cb(res, null);
    }
  });

// test case
  //   const promiseNew = new Promise((resolve, reject) => {
  //   setTimeout(() => {
  //     resolve( { "data" : {"msg":"OTP SENT","otpGenerationStatus":"1"}}
  // );
  //   }, 1000);
  // });
  // promiseNew.then((resolvedValue) => {
  //   cb(resolvedValue);  
  // });
// test case

}
export function indianStateApiData(params, cb) {
  API.GET(PATHS.GET_GET_STATTE, params, {}, (res, err) => {
    if (err) {
      cb(null, err);
    } else {
      cb(res, null); 
    }
  });
}
export function tdfFetchReport(params, cb) {
  API.POST(PATHS.POST_FETCH_REPORT, params, {}, (res, err) => {
    if (err) {
      cb(null, err);
    } else {
      cb(res, null); 
    }
  });
  // test case start
  // const promiseNew = new Promise((resolve, reject) => {
  //   setTimeout(() => {
  //     resolve( { "data" : {
  //       "msg": "OTP SENT",
  //       "otpGenerationStatus": "1"
  //   }}
  // );
  //   }, 1000);
  // });
  // promiseNew.then((resolvedValue) => {
  //   cb(resolvedValue);  
  // });
  // test case end

}

export function postUserProfile(params, cb) {
  API.POST(PATHS.POST_USER_PROFILE, params, {}, (res, err) => {
    if (err) {
      cb(null, err);
    } else {
      cb(res, null);
    }
  });
}
export function postCreditOtp(params, cb) {
  API.POST(PATHS.POST_VALIDATE_CREDIT_OTP, params, {}, (res, err) => {
    if (err) {
      cb(null, err); 
    } else {
      cb(res, null); 
    }
  });

    // test case start

  //   const promiseNew = new Promise((resolve, reject) => {
  //   setTimeout(() => {
  //     resolve( { "data" : {
  //       "customerDataResponse": null,
  //       "myReport": null,
  //       "data": false,
  //       "experian": false,
  //       "error": "consumer record not found",
  //       "openForm": true,
  //   }}
  // );
  //   }, 1000);
  // });
  // promiseNew.then((resolvedValue) => {
  //   cb(resolvedValue);  
  // });
  // test case end
}
