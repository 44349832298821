import React from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import logo from "../assets/imgs/DUE-FACTORY.webp";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useNavigate } from "react-router-dom";
import "./style.css"

function FotterComponent() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const fontSize = isSmallScreen ? "12px" : "16px";

  const handleIconClick = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const handlegoContact = () => {
    navigate("/contact-us");
    window.scrollTo(0, 0);
  };
  const handlegoAbout =()=>{
    navigate("/about-us");
    window.scrollTo(0, 0);
  }
  const gototermandcandition =()=>{
    navigate("/terms-&-conditions");
    window.scrollTo(0, 0);

  }
  const handleGoBlog =()=>{
    window.location.href = "/blog/";

  }
  const handleGoHome =()=>{
    navigate("/")
    window.scrollTo(0, 0);
  }
  return (
    <>
      <Grid
        container
        // spacing={2}
        style={{
          background: "#fcfcfc",
          borderTop: "1px solid rgba(128, 128, 128, 0.11)",
          // marginTop: "40px",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={12} lg={4} md={4}>
          <Box sx={{ padding: "12px",   
              display:"flex",       justifyContent: { xs: "center", sm: "center", lg: "flex-start", md: "flex-start" },
               }}>
            <Box className="img-top-div-1"> 
             
              <div className="img-mobile-center-div">    
              <img
                src={logo}
                alt="logo"
                style={{
                  width: "152px",
                  height: "auto",
                  cursor: "pointer",
                }}
                onClick={handleGoHome}
              />
              </div>
              <Typography  className="tagline-text-div">
                Better credit score. New possibilities.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} lg={4}  md={4}>
          <Box style={{ padding: "12px" }}>
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Typography 
              // style={{ cursor: "pointer", fontSize, fontFamily:"CustomFontMedium" }}
              className="fotter-text-div-tdf-11"
                onClick={handlegoAbout}>
                About Us
              </Typography>
              <Typography
                // style={{
                //   marginLeft: "15px",
                //   cursor: "pointer",
                //   fontSize,
                //    fontFamily:"CustomFontMedium"
                // }}
                className="fotter-text-div-tdf-112"
                onClick={handlegoContact}
              >
                Contact Us
              </Typography>
              <Typography
                // style={{
                //   marginLeft: "15px",
                //   cursor: "pointer",
                //   fontSize,
                //    fontFamily:"CustomFontMedium"
                // }}
                className="fotter-text-div-tdf-112"

                onClick={gototermandcandition}
                
              >
                Terms & conditions
              </Typography>
              <Typography
                // style={{
                //   marginLeft: "15px",
                //   cursor: "pointer",
                //   fontSize,
                //    fontFamily:"CustomFontMedium"
                // }}
                className="fotter-text-div-tdf-112"

                onClick={handleGoBlog}
              >
                Blogs
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} lg={4}  md={4}>
          <Box style={{ padding: "12px" }}>
            <Box
              sx={{
                // padding: "12px",
                display: "flex",
                justifyContent:{xs:"center",sm:"center",lg:"end"},
                gap: "20px",
                marginRight:{xs:"none", sm:"none", lg:"25px"}
              }}
            >
              <FacebookIcon
                sx={{
                  color: "#fff",
                  backgroundColor: "#3b5998",
                  borderRadius: "50%",
                  padding: "5px",
                  cursor: "pointer",
                  "&:hover": { opacity: 0.8 },
                }}
                onClick={() =>
                  handleIconClick("https://www.facebook.com/duefactory")
                }
              />
              <InstagramIcon
                sx={{
                  color: "#fff",
                  backgroundColor: "#e4405f",
                  borderRadius: "50%",
                  padding: "5px",
                  cursor: "pointer",
                  "&:hover": { opacity: 0.8 },
                  
                }}
                onClick={() =>
                  handleIconClick("https://www.instagram.com/duefactory/")
                }
              />
              <XIcon
                sx={{
                  color: "#fff",
                  backgroundColor: "#000000",
                  borderRadius: "50%",
                  padding: "5px",
                  cursor: "pointer",
                  "&:hover": { opacity: 0.8 },
                }}
                onClick={() =>
                  handleIconClick("https://www.twitter.com/duefactory")
                }
              />
              <LinkedInIcon
                sx={{
                  color: "#fff",
                  backgroundColor: "#0077b5",
                  borderRadius: "50%",
                  padding: "5px",
                  cursor: "pointer",
                  "&:hover": { opacity: 0.8 },
                }}
                onClick={() =>
                  handleIconClick(
                    "https://www.linkedin.com/company/duefactory/"
                  )
                }
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        // spacing={2}
        style={{
          width: "100%",
          background: "#2c303a",
          // marginTop: "40px",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={12} lg={12}>
          <Box style={{padding:"20px",textAlign:"center"}}>
            <Typography style={{color:"white",fontSize:"14px",opacity:"0.8", fontFamily:"CustomFontMedium"}}>
              Copyright © Due Factory Services Private Limited - All rights
              reserved
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default FotterComponent;
