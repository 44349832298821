import apiClient from "./apiClient";
import API_ENDPOINTS from "./apiEndpoints";


const apiService = {
   


  postInsight:() => {
    return apiClient.post(API_ENDPOINTS.POST_INSIGHT(), {}) // Return promise directly
      .then((res) => {
        return res; // Response will be available in .then of the caller
      })
      .catch((err) => {
        console.error("Error generating OTP:", err.message);
        throw err; // Re-throw error for the caller to handle
      });
  },

 
  generateOtp:(number) => {
    return apiClient
      .post(API_ENDPOINTS.GET_OTP(number), {}) // Return promise directly
      .then((res) => {
        return res; // Response will be available in .then of the caller
      })
      .catch((err) => {
        console.error("Error generating OTP:", err.message);
        throw err; // Re-throw error for the caller to handle
      });
  },
  
  fetchUserReport:  () => {
    try {
      const res =  apiClient.get(API_ENDPOINTS.USER_GET_REPORT());
      return res; // Return the data directly
    } catch (err) {
      console.error("Error fetching profile details:", err);
      throw err; // Re-throw for error handling
    }
  },

  contactUsApiData:  (formData) => {
    try {
      const res =  apiClient.post(API_ENDPOINTS.POST_CONTACT_MSG(),formData);
      return res; // Return the data directly
    } catch (err) {
      console.error("Error fetching profile details:", err);
      throw err; // Re-throw for error handling
    }
  },
  revokeConsent:  () => {
    try {
      const res =  apiClient.post(API_ENDPOINTS.POST_REVOKECONSENT(),{});
      return res; // Return the data directly
    } catch (err) {
      console.error("Error fetching profile details:", err);
      throw err; // Re-throw for error handling
    }
  },
  // userTrackData:  (number) => {
  //   try {
  //     const res =  apiClient.get(API_ENDPOINTS.GET_USER_TRACK(number));
  //     return res; // Return the data directly
  //   } catch (err) {
  //     console.error("Error fetching profile details:", err);
  //     throw err; // Re-throw for error handling
  //   }
  // },

  userTrackData: (number) => {
    return apiClient.get(API_ENDPOINTS.GET_USER_TRACK(number)); // Promise directly returned
  },
  authToken: ({ username, password }) => {
    try {
      const res =  apiClient.post(API_ENDPOINTS.POST_AUTH_TOKEN(),{username, password,});
      return res; // Return the data directly
    } catch (err) {
      console.error("Error fetching profile details:", err);
      throw err; // Re-throw for error handling
    }
  },
  // test this api saveUserDataApi
  saveUserDataApi:  (requestData) => {
    try {
      const res =  apiClient.post(API_ENDPOINTS.POST_SAVE_USER_TRACK(),requestData);
      return res; // Return the data directly
    } catch (err) {
      console.error("Error fetching profile details:", err);
      throw err; // Re-throw for error handling
    }
  },

  callBackApi:  (accountDetailsId) => {
    try {
      const res =  apiClient.post(API_ENDPOINTS.POST_CALL_ME_BACK(),{accountDetailsId});
      return res; // Return the data directly
    } catch (err) {
      console.error("Error fetching profile details:", err);
      throw err; // Re-throw for error handling
    }
  },
  // POST_GETING_PAYMENT_LINK
  getPaymentlinkdata:  (productType, accountDetailsId, bankName) => {
    try {
      const res =  apiClient.post(API_ENDPOINTS.POST_GETING_PAYMENT_LINK(),{productType, accountDetailsId, bankName});
      return res; // Return the data directly
    } catch (err) {
      console.error("Error fetching profile details:", err);
      throw err; // Re-throw for error handling
    }
  },
   counterOffterData:  ({accountDetailsId,amount,phone}) => {
    try {
      const res =  apiClient.post(API_ENDPOINTS.POST_COUNTER_OFFER(),{accountDetailsId,amount,phone});
      return res; // Return the data directly
    } catch (err) {
      console.error("Error fetching profile details:", err);
      throw err; // Re-throw for error handling
    }
  },
  settlementListingApiData:  () => {
    try {
      const res =  apiClient.post(API_ENDPOINTS.POST_SETTLEMENT_LISTING(),{});
      return res; // Return the data directly
    } catch (err) {
      console.error("Error fetching profile details:", err);
      throw err; // Re-throw for error handling
    }
  },
  profileApiData:  () => {
    try {
      const res =  apiClient.get(API_ENDPOINTS.GET_PROFILE_DETAIL());
      return res; // Return the data directly
    } catch (err) {
      console.error("Error fetching profile details:", err);
      throw err; // Re-throw for error handling
    }
  },
  fetchReportData:  (updatedFormData) => {
    try {
      const res =  apiClient.post(API_ENDPOINTS.POST_ENHANCED_FETCH_REPORT(updatedFormData));
      return res; // Return the data directly
    } catch (err) {
      console.error("Error fetching profile details:", err);
      throw err; // Re-throw for error handling
    }
  },
  indianStateApiData:  () => {
    try {
      const res =  apiClient.get(API_ENDPOINTS.GET_GET_STATTE());
      return res; // Return the data directly
    } catch (err) {
      console.error("Error fetching profile details:", err);
      throw err; // Re-throw for error handling
    }
  },
  tdfFetchReport:  (formData) => {
    try {
      const res =  apiClient.post(API_ENDPOINTS.POST_FETCH_REPORT(),formData);
      return res; // Return the data directly
    } catch (err) {
      console.error("Error fetching profile details:", err);
      throw err; // Re-throw for error handling
    }
  },



  




};

export default apiService;
