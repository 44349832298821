import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import SidebarScreen from '../Components/Sidebar/SidebarScreen';
import TopbarScreen from '../Components/Topbar/TopbarScreen';
import "./style.css";
import { useSelector } from 'react-redux';

const DashboardLayout = () => {
    const [innerWidth, setinnerWidth] = useState(0);
    const isOpen = useSelector((state) => state.sidebar.isOpen);
    const isHalfOpen = useSelector((state) => state.sidebar.isHalfOpen);
    const sidebarWidth = useSelector((state) => state.sidebar.sidebarWidth);
    useEffect(() => {
        const handleResize = () => {
            setinnerWidth(window.innerWidth);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div className='dashboard-layout'>
            <div
                style={{
                    width:
                        sidebarWidth
                        -
                        (window.innerWidth == 900 ? (40) : (0))
                        +
                        (window.innerWidth > 900 ? (40) : (0))
                }}
                className="dashboard-layout__left">
                <SidebarScreen />
            </div>
            <div className="dashboard-layout__right">
                <TopbarScreen />
                <Outlet />
            </div>
        </div >
    );
};

export default DashboardLayout;
