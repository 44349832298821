import React, { useEffect, useState } from "react";
import banner from "../assets/imgs/TDF-full-img.webp";
import { GiCheckMark } from "react-icons/gi";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  MenuItem,
  FormControl,
  Select,
  FormControlLabel,
  Checkbox,
  Switch,
  Modal,
  Container,
  CircularProgress,
} from "@mui/material";
import axiosInstance from "../service/axiosInstance";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../Components/Loading";
import { useLocation } from "react-router-dom";
import HeaderComponent from "../Components/HeaderCoponent";
import "../Pages/modal.css";
import apiService from "../service/apiService";
import { fetchReportData } from "../service/ApiList";
import OtpVerification from "./OtpVerification";
function BasicDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(true);
  const [checkedWhatsApp, setCheckedWhatsApp] = useState(true);

  const [showFirstSet, setShowFirstSet] = useState(true);
  const mobile = location?.state?.mobile;

  const [formData, setFormData] = useState({
    firstName: "",
    surName: "",
    gender: "",
    email: "",
    whatsAppConsent: checkedWhatsApp,
    experianConsent: isChecked,
  });
  const [enhancedFetchReport, setnEhancedFetchReport] = useState({});
  const [stateLifitingUp, setStateLifitingUp] = useState(false);
  const [mobileNumber, setMobileNumber] = useState(mobile);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [UTMData, setUTMData] = useState("");
  const userProfileData = location?.state?.userProfileData;



  const handleChange = (e) => {
    const { name, value } = e.target;

    // Regex to allow only alphabetic characters and limit to 15 characters
    // const regex = /^[A-Za-z]{0,25}$/;
    const regex = /^[A-Za-z\s]{0,25}$/;

    if (name === "name" || name === "surName") {
      if (regex.test(value)) {
        const formattedValue =
          value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
        setFormData({ ...formData, [name]: formattedValue });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const combinedData = { ...(userProfileData ), ...formData };
    if(userProfileData){
      localStorage.setItem("userProfileData", JSON.stringify(combinedData));
    }  

    const getLocalStorage = localStorage.getItem("TDFToken");
    const token = getLocalStorage;
    // UTM Start
    const utmCampaigns = localStorage.getItem("utmCampaign");
    let utmCampaign = "";
    let utmMedium = "";
    let utmSource = "";
    let utmContent = "";
    // geeting these LS items from the FB Campaign param URL
    const utm_sourceFB = localStorage.getItem("utm_source");
    const utm_mediumFB = localStorage.getItem("utm_medium");
    const utm_campaignFB = localStorage.getItem("utm_campaign");
    const utm_contentFB = localStorage.getItem("utm_content");

    if (utmCampaigns) {
      // Convert utmCampaign to a URLSearchParams object
      const params = new URLSearchParams(utmCampaigns);

      // Extract the values
      utmCampaign = params.get("utm_campaign") || "";
      utmMedium = params.get("utm_medium") || "";
      utmSource = params.get("utm_source") || "";
      utmContent = params.get("utm_content") || "";
    }

    // UTM end

    const updatedFormData = {
      ...formData,
      ...(utm_campaignFB || utmCampaign
        ? { utmCampaign: utm_campaignFB || utmCampaign }
        : {}),
      ...(utm_mediumFB || utmMedium
        ? { utmMedium: utm_mediumFB || utmMedium }
        : {}),
      ...(utm_sourceFB || utmSource
        ? { utmSource: utm_sourceFB || utmSource }
        : {}),
      ...(utm_contentFB || utmContent
        ? { utmContent: utm_contentFB || utmContent }
        : {}),
    };
    setUTMData(updatedFormData);

    fetchReportData(updatedFormData, (res, err) => {
      if (res) {
        setStateLifitingUp(false);
        localStorage.removeItem("utmCampaign");
        setnEhancedFetchReport(res);
        toast.success(res?.data?.msg);
        setLoading(false);
        // navigate("/login/otpverification", {
        //   state: { updatedFormData: updatedFormData },
        // });
        localStorage.removeItem("utm_source");
        localStorage.removeItem("utm_medium");
        localStorage.removeItem("utm_campaign");
        localStorage.removeItem("utm_content");
      } else {
        toast.error(err?.message);
        setLoading(false);
      }
    });
  };

  useEffect(() => {   
  }, []);
  useEffect(() => {
    if (userProfileData) {
      localStorage.setItem("userProfileData", JSON.stringify(userProfileData));
    }
    // if(userProfileData?.status === null ){
    //   localStorage.setItem("formData", JSON.stringify(userProfileData));

    // }
  }, [userProfileData]);

  useEffect(() => {
    const storedData = localStorage.getItem("userProfileData");
    if (storedData) {
      const userProfileData = JSON.parse(storedData);
      setFormData({
        ...formData,
        firstName: userProfileData?.name,
        surName: userProfileData?.surName,
        gender: userProfileData?.gender,
        email: userProfileData?.email,
      });
      const savedMobile = localStorage.getItem("mobile");
      if (savedMobile) {
        setMobileNumber(savedMobile);
      }else{
        setMobileNumber(userProfileData?.mobile);
      }      
    }
  }, []);

  const handleChangeCheckedWhats = (event) => {
    setCheckedWhatsApp(event.target.checked);
  };
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      whatsAppConsent: checkedWhatsApp,
    }));
  }, [checkedWhatsApp]);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowFirstSet((prevShowFirstSet) => !prevShowFirstSet);
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
            // navigate("/");
            const isConfirmed = window.confirm("Are you sure you want to leave? All unsaved changes will be lost.");
            if (isConfirmed) {
              navigate("/"); // Navigate back if the user confirms
            }
    };
    const handleBeforeUnload = (event) => {
      const message =
        "Are you sure you want to leave? All unsaved changes will be lost.";
      event.returnValue = message;
      return message;
    };

    // Push a new entry to the history stack
    window.history.pushState(null, null, window.location.href);

    // Add event listener for the 'popstate' event
    window.addEventListener("popstate", handlePopState);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("popstate", handlePopState);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [navigate]);

  return (
    <>
      {enhancedFetchReport?.data && stateLifitingUp === false ? (
        <>
          <OtpVerification
            UTMData={UTMData}
            setStateLifitingUp={setStateLifitingUp}
          />
        </>
      ) : (
        <>
          {loading && (
            <Box
              sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(252, 240, 240, 0.5)", // Semi-transparent background
                zIndex: 1300, // Higher than other elements
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress size={60} sx={{ color: "#ffffff" }} />
            </Box>
          )}
          <>
            <Box
              style={{
                background: "#044A67",
                width: "100%",
                minHeight: "100vh",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <HeaderComponent />

              <Box
                sx={{
                  padding: { xs: "none", sm: "20px", lg: "20px", md: "20px" },
                }}
              >
                <Grid container spacing={1}>
                  {/* Hero Section Start */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    lg={6}
                    md={6}
                    sx={{
                      order: { sm: 1, xs: 2, md: 1, lg: 1 },
                      paddingBottom: { xs: "60px", sm: "0px" },

                      paddingLeft: "20px",
                    }}
                  >
                    <img
                      src={banner}
                      alt="pullLogo"
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        width: "80%",
                      }}
                    />
                    <Box
                      style={{
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                        lineHeight: "30px",
                        fontSize: "24px",
                        marginTop: "10px",
                        fontFamily: "CustomFontMedium",
                        textAlign: "center",
                      }}
                    >
                      When you share your details, you’ll get:
                    </Box>
                    <Box
                      sx={{
                        marginTop: "10px",
                        width: { lg: "50%", sm: "50%" },
                        marginLeft: { lg: "20%", sm: "20%" },
                        padding: "10px",
                        height: "25vh",
                      }}
                    >
                      {showFirstSet ? (
                        <>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            <GiCheckMark
                              style={{
                                color: "#ffff",
                                marginTop: "5px",
                                fontSize: "12px",
                              }}
                            />
                            <Typography
                              style={{
                                color: "#ffff",
                                marginLeft: "10px",
                                fontFamily: "CustomFontMedium",
                              }}
                            >
                              Free Experian Credit Score and Report
                            </Typography>
                          </Box>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              marginTop: "10px",
                            }}
                          >
                            <GiCheckMark
                              style={{ color: "#ffff", marginTop: "5px" }}
                            />
                            <Typography
                              style={{
                                color: "#ffff",
                                marginLeft: "10px",
                                fontFamily: "CustomFontMedium",
                              }}
                            >
                              Monitor your score improvements - Report and Score
                              Refreshed Every 45 Days, on Sign In
                            </Typography>
                          </Box>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              marginTop: "10px",
                            }}
                          >
                            <GiCheckMark
                              style={{ color: "#ffff", marginTop: "5px" }}
                            />
                            <Typography
                              style={{
                                color: "#ffff",
                                marginLeft: "10px",
                                fontFamily: "CustomFontMedium",
                              }}
                            >
                              Complimentary Alerts and Recommendations from Due
                              Factory
                            </Typography>
                          </Box>
                        </>
                      ) : (
                        <>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "10px",
                            }}
                          >
                            <GiCheckMark
                              style={{ color: "#ffff", marginTop: "5px" }}
                            />
                            <Typography
                              style={{
                                color: "#ffff",
                                marginLeft: "10px",
                                fontFamily: "CustomFontMedium",
                              }}
                            >
                              We help you prioritize your repayments based on
                              deep data insights.
                            </Typography>
                          </Box>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "10px",
                            }}
                          >
                            <GiCheckMark
                              style={{ color: "#ffff", marginTop: "5px" }}
                            />
                            <Typography
                              style={{
                                color: "#ffff",
                                marginLeft: "10px",
                                fontFamily: "CustomFontMedium",
                              }}
                            >
                              Take decisions which change your future for the
                              better.
                            </Typography>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Grid>
                  {/* Hero Section Start */}
                  <Grid
                    // item
                    xs={12}
                    sm={12}
                    lg={6}
                    md={6}
                    sx={{
                      order: { sm: 2, xs: 1, md: 2, lg: 2 },
                      paddingRight: "20px",
                      paddingLeft: "25px",
                      marginBottom: "20px",
                      marginTop: { xs: "25px", sm: "0px" },
                      display:"flex",
                      alignItems:"center",
                      justifyContent:"center"
                    }}
                  >
                    <Box
                      sx={{
                        border: "2px solid whitesmoke",
                        width: { sm: "100%", lg: "100%" },
                        background: "#FFFFFF",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        borderRadius: "5px",
                      }}
                    >
                      <Box
                        sx={{
                          padding: {
                            lg: "40px",
                            sm: "20px",
                            xs: "20px",
                          },
                        }}
                      >
                        <Typography
                          variant="h5"
                          style={{
                            marginBottom: "20px",
                            fontSize: "24px",
                            fontWeight: "400",
                            fontFamily: "CustomFontMedium",
                          }}
                        >
                          Verify /Fill personal details
                        </Typography>
                        <form onSubmit={handleSubmit}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} lg={6}>
                              <label>First Name</label>
                              <TextField
                                type="text"
                                style={{ width: "100%", marginTop: "5px" }}
                                placeholder="Enter First Name"
                                value={formData.firstName}
                                name="firstName"
                                onChange={handleChange}
                                size="small"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                              <label>Last Name</label>
                              <TextField
                                type="text"
                                style={{ width: "100%", marginTop: "5px" }}
                                placeholder="Enter Last Name"
                                value={formData.surName}
                                name="surName"
                                onChange={handleChange}
                                size="small"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                              <label>Phone Number</label>
                              <TextField
                                type="number"
                                style={{ width: "100%", marginTop: "5px" }}
                                placeholder="Enter Phone Number"
                                value={mobileNumber}
                                name="mobileNo"
                                // onChange={handleChangeNumber}
                                size="small"
                                id="phone-input"
                                disabled
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                              <label>Gender</label>
                              <FormControl fullWidth>
                                <Select
                                  value={formData.gender}
                                  onChange={handleChange}
                                  placeholder="Gender"
                                  size="small"
                                  style={{ marginTop: "5px" }}
                                  name="gender"
                                  displayEmpty
                                >
                                  <MenuItem value="" disabled>
                                    Select Gender
                                  </MenuItem>
                                  <MenuItem value={1}>Male</MenuItem>
                                  <MenuItem value={2}>Female</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12}>
                              <label>Email</label>
                              <TextField
                                type="email"
                                style={{ width: "100%", marginTop: "5px" }}
                                placeholder="Enter Email"
                                value={formData.email}
                                name="email"
                                onChange={handleChange}
                                size="small"
                              />
                            </Grid>

                            <Grid item xs={12} sm={12} lg={12}>
                              <Box style={{ marginTop: "20px" }}>
                                <FormControlLabel
                                  control={
                                    <Checkbox style={{ color: "#044a67" }} />
                                  }
                                  checked={isChecked}
                                  onChange={handleCheckboxChange}
                                  label={
                                    <Box style={{ display: "flex" }}>
                                      <Typography
                                        style={{
                                          fontSize: "12px",
                                          fontFamily: "CustomFontMedium",
                                        }}
                                      >
                                        I hereby appoint <b>Due Factory</b> as
                                        my authorised representative to receive
                                        my credit information from{" "}
                                        <span
                                          style={{ color: "#044a67" }}
                                          onClick={handleOpenModal}
                                        >
                                          Experian (Terms and Conditions)
                                        </span>{" "}
                                        for credit enhancement services.
                                      </Typography>
                                    </Box>
                                  }
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12}>
                              <Box style={{ marginTop: "20px" }}>
                                <Button
                                  variant="contained"
                                  type="submit"
                                  style={{
                                    background:
                                      formData.firstName &&
                                      formData.surName &&
                                      formData.email &&
                                      formData.gender &&
                                      // formData.mobileNo &&
                                      isChecked
                                        ? "#044A67"
                                        : "#d3d3d3",
                                    color:
                                      formData.firstName &&
                                      formData.surName &&
                                      formData.email &&
                                      formData.gender &&
                                      // formData.mobileNo &&
                                      isChecked
                                        ? "#FFFFFF"
                                        : "rgba(0, 0, 0, .26)",
                                    width: "100%",
                                    textTransform: "capitalize",
                                  }}
                                  disabled={
                                    !formData.firstName ||
                                    !formData.surName ||
                                    !formData.email ||
                                    !formData.gender ||
                                    // !formData.mobileNo ||
                                    !isChecked ||
                                    loading
                                  }
                                >
                                  <> Pull free Credit Report</>
                                </Button>
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12}>
                              <Box style={{ marginTop: "10px" }}>
                                <Typography
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: "CustomFontMedium",
                                  }}
                                >
                                  Your personal information is 100% secured with
                                  us. We do not share your data with any third
                                  party.
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12}>
                              <Box style={{ marginTop: "10px" }}>
                                <FormControlLabel
                                  control={<Switch />}
                                  label="Get updates on WhatsApp"
                                  checked={checkedWhatsApp}
                                  onChange={handleChangeCheckedWhats} // <-- Handler function to update state
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </form>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            {/* Modal Start */}

            <Modal
              open={openModal}
              onClose={handleCloseModal}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Container
                className="modal-container"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: { xs: "90vh", md: "80vh", lg: "80vh" },
                  // maxHeight: "80vh",
                  backgroundColor: "white",
                  padding: { xs: "10px", md: "20px" },
                  borderRadius: "20px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                  width: {
                    lg: "80%",
                    sm: "80%",
                    xs: "90%",
                  },
                  overflowY: "hidden", // Hide overflow on the container
                }}
              >
                <Box
                  sx={{
                    padding: "20px",
                    flexGrow: 1,
                    overflowY: "auto", // Allow scrolling within this box
                    paddingRight: "10px", // Add some padding to prevent the scrollbar from overlapping content
                  }}
                >
                  <Typography
                    id="modal-title"
                    variant="h6"
                    component="h2"
                    style={{
                      fontSize: "13px",
                      fontFamily: "Arial",
                      color: "black",
                    }}
                  >
                    <strong
                      style={{
                        textDecoration: "underline",
                        fontFamily: "CustomFontMedium",
                      }}
                    >
                      {" "}
                      CONSENT IN RELATION TO ACCESS TO CREDIT INFORMATION
                      THROUGH EXPERIAN
                    </strong>
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px",
                    }}
                  >
                    This End User Agreement (the <strong>“Agreement”</strong>)
                    is made between you (the <strong>“User” or “You”</strong> )
                    and <strong>Due Factory Services Private Limited</strong>{" "}
                    (hereinafter referred to as <strong>“Client”</strong>), a
                    private limited company having its registered office at
                    806-807, Udyog Vihar Phase V, Sector 19, Gurugram, Haryana –
                    122016 (<strong>“CLIENT”</strong>, <strong>“Us”</strong> or{" "}
                    <strong>“We”</strong> , which term shall include its
                    successors and permitted assigns). The User and CLIENT shall
                    be collectively referred to as the{" "}
                    <strong>“Parties”</strong>
                    and individually as a <strong>“Party”</strong> .
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px",
                    }}
                  >
                    You hereby consent to Due Factory Services Private Limited
                    being appointed as your authorised representative to receive
                    your Credit Information from Experian for the purpose of
                    Credit Assessment of the End User to Advise him on the
                    credit score enhancement and best loan offers (End Use
                    Purpose) or expiry of 6 months from the date the consent is
                    collected; whichever is earlier.
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px",
                    }}
                  >
                    <strong>
                      BY EXECUTING THIS AGREEMENT / CONSENT FORM, YOU ARE
                      EXPRESSLY AGREEING TO ACCESS THE EXPERIAN CREDIT
                      INFORMATION REPORT AND CREDIT SCORE, AGGREGATE SCORES,
                      INFERENCES, REFERENCES AND DETAILS (AS DEFINED
                      BELOW)(TOGETHER REFERRED AS “CREDIT INFORMATION”). YOU
                      HEREBY ALSO CONSENT TO SUCH CREDIT INFORMATION BEING
                      PROVIDED BY EXPERIAN TO YOU AND DUE FACTORY SERVICES
                      PRIVATE LIMITED BY USING EXPERIAN TOOLS, ALGORITHMS AND
                      DEVICES AND YOU HEREBY AGREE, ACKNOWLEDGE AND ACCEPT THE
                      TERMS AND CONDITIONS SET FORTH HEREIN.
                    </strong>
                  </Typography>{" "}
                  <Typography
                    id="modal-title"
                    variant="h6"
                    component="h2"
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      color: "black",
                      marginTop: "10px",
                    }}
                  >
                    <strong style={{ textDecoration: "underline" }}>
                      {" "}
                      Terms and Conditions:
                    </strong>
                  </Typography>
                  <Typography
                    id="modal-title"
                    variant="h6"
                    component="h2"
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      color: "black",
                      marginTop: "10px",
                    }}
                  >
                    <strong>
                      {" "}
                      Information Collection, Use, Confidentiality,
                      No-Disclosure and Data Purging
                    </strong>
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px",
                    }}
                  >
                    DUE FACTORY SERVICES PRIVATE LIMITED shall access your
                    Credit Information as your authorized representative and DUE
                    FACTORY SERVICES PRIVATE LIMITED shall use the Credit
                    Information for limited End Use Purpose consisting of and in
                    relation to the services proposed to be availed by you from
                    DUE FACTORY SERVICES PRIVATE LIMITED. We shall not
                    aggregate, retain, store, copy, reproduce, republish,
                    upload, post, transmit, sell or rent the Credit Information
                    to any other person and the same cannot be copied or
                    reproduced other than as agreed herein and in furtherance to
                    applicable law including the CICRA.
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px",
                    }}
                  >
                    The Parties agree to protect and keep confidential the
                    Credit Information both online and offline.
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px",
                    }}
                  >
                    The Credit Information shared by you, or received on by us
                    on your behalf shall be destroyed, purged, erased
                    immediately upon the completion of the transaction/ End Use
                    Purpose for which the Credit Information report was
                    procured, this period not being longer than 6 months.
                  </Typography>
                  <Typography
                    id="modal-title"
                    variant="h6"
                    component="h2"
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      color: "black",
                      marginTop: "10px",
                    }}
                  >
                    <strong> Governing Law and Jurisdiction</strong>
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px",
                    }}
                  >
                    The relationship between you and DUE FACTORY SERVICES
                    PRIVATE LIMITED shall be governed by laws of India and all
                    claims or disputes arising there from shall be subject to
                    the exclusive jurisdiction of the courts of Mumbai.
                  </Typography>
                  <Typography
                    id="modal-title"
                    variant="h6"
                    component="h2"
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      color: "black",
                      marginTop: "10px",
                    }}
                  >
                    <strong>Definitions:</strong>
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px",
                    }}
                  >
                    Capitalised terms used herein but not defined above shall
                    have the following meanings:
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px",
                    }}
                  >
                    <strong>“Business Day”</strong> means a day (other than a
                    public holiday) on which banks are open for general business
                    in Mumbai.
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px",
                    }}
                  >
                    <strong>“Credit Information Report”</strong> means the
                    credit information / scores/ aggregates / variables /
                    inferences or reports which shall be generated by Experian;
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px",
                    }}
                  >
                    <strong>“Credit Score”</strong> means the score which shall
                    be mentioned on the Credit Information Report which shall be
                    computed by Experian.
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px",
                    }}
                  >
                    <strong>“CICRA”</strong>shall mean the Credit Information
                    Companies (Regulation) Act, 2005 read with the Credit
                    Information Companies Rules, 2006 and the Credit Information
                    Companies Regulations, 2006, and shall include any other
                    rules and regulations prescribed thereunder.
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px",
                    }}
                  >
                    PLEASE READ THE ABOVEMENTIONED TERMS AND CONDITIONS AND
                    CLICK ON “ACCEPT” FOLLOWED BY THE LINK BELOW TO COMPLETE THE
                    AUTHORISATION PROCESS/ FOR SHARING OF YOUR CREDIT
                    INFORMATION BY EXPERIAN WITH DUE FACTORY SERVICES PRIVATE
                    LIMITED IN ITS CAPACITY AS YOUR AUTHORISED REPRESENTATIVE.
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px",
                    }}
                  >
                    BY CLICKING “ACCEPT” YOU AGREE AND ACCEPT THE DISCLAIMERS
                    AND TERMS AND CONDITIONS SET OUT HEREIN.
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px",
                    }}
                  >
                    ** This document is an electronic record in terms of the
                    Information Technology Act, 2000 and Rules made there under,
                    and the amended provisions pertaining to electronic records.
                  </Typography>
                </Box>

                <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCloseModal}
                    style={{
                      background: "#05517a",
                      color: "white",
                      borderRadius: "10px",
                      marginTop: "20px",
                      fontFamily: "CustomFontMedium",
                    }}
                  >
                    Close
                  </Button>
                </Box>
              </Container>
            </Modal>
            {/* Modal End */}
          </>
        </>
      )}
    </>
  );
}
export default BasicDetails;
