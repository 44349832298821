import React from "react";
import img2 from "../assets/imgs/car-2.webp";
import { Box, Grid, Typography } from "@mui/material";
import "./style.css"

const Page2 = () => {
  return (
    <Box>
      <Grid container>
        <Grid
          item
          xs={0}
          sm={6}
          lg={6}
          xl={5}
          sx={{ display: { xs: "none", sm: "block", lg: "block" } }}
        >
          <Box
              className="desktop-site-div-112"
          >
            <div style={{height:"67%", display:"flex",alignContent:"center", justifyContent:"center"}}>
                   

            <img
              src={img2}
              alt="mobile"
              width={"100%"}
              // height="70%"
             className="img-div-112-tdf"
              loading="lazy"
              style={{ minHeight: '395px' }} 

            />
                </div>   
          </Box>
        </Grid>
        {/* How Its Works Section Start */}
        <Box>
          <svg
            className="editorial"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
          >
            <defs>
              <path
                id="gentle-wave"
                d="M-160 44c30 0 
                    58-18 88-18s
                    58 18 88 18 
                    58-18 88-18 
                    58 18 88 18
                    v44h-352z"
              />
            </defs>
            <g className="parallax1">
              <use xlinkHref="#gentle-wave" x="50" y="3" fill="#f461c1" />
            </g>
            <g className="parallax2">
              <use xlinkHref="#gentle-wave" x="50" y="0" fill="#4579e2" />
            </g>
            <g className="parallax3">
              <use xlinkHref="#gentle-wave" x="50" y="9" fill="#3461c1" />
            </g>
            <g className="parallax4">
              <use xlinkHref="#gentle-wave" x="50" y="6" fill="#fff" />
            </g>
          </svg>
        </Box>
        <Grid
          item
          xs={0}
          sm={6}
          lg={6}
          xl={7}
          sx={{
            display: { xs: "block", sm: "flex", lg: "flex" },
            background: "#bde4f7",justifyContent:"center",alignItems:"center"
          }}
        >
          <Box
            sx={{
              display:"flex",
              flexDirection:"column",
              justifyContent:"center",            
              textAlign: "justify",
              padding: "0 100px 30px 45px",
              position: "relative",
            }}
          >
            <Box
                className="num-of-carousel-div-112"
            >
              2
            </Box>
            <Typography
              style={{ fontSize: "26px", fontFamily: "CustomFontMedium" }}
            >
              How It Works?
            </Typography>
            <Typography        
           className="at-due-factory-text"
            >
              At Due Factory, we help you bring up your sub-optimal credit score
              which is a result of your financial history. Missed EMIs on a
              previous loan? Forgot to pay your CC bill months ago? Having
              difficulty in getting a new loan?
            </Typography>
            <Box  className="crasoul-div-heading-112">
              <Typography
         className="crasoul-text-div-123"
              >
                Identify your bad loans
              </Typography>
              <Typography
                      className="at-due-factory-text"

              >
                Based on your previous payment history. Identify loans and
                Credit cards which you must start addressing in order to elevate
                your credit health.
              </Typography>
            </Box>
          </Box>
        </Grid>
        {/* How Its Works Section end */}
      </Grid>
    </Box>
  );
};

export default Page2;
