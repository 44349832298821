import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  Hidden,
  Modal,
  Container,
} from "@mui/material";
import credilio from "../assets/imgs/credilio2.webp";
import axiosInstance from "../service/axiosInstance";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";

function CredilioCardPlus() {
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleCredilioPlus = () => {
    const token = localStorage.getItem("TDFToken");
    axiosInstance
      .get("/tdf/api/profile/getRedirectionUrl", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (!res?.data?.success) {
          toast.error(res?.data?.message);
          handleOpenModal();
        }
        if (res?.data?.success) {
          window.open(res?.data?.response);
        }
      })
      .catch((err) => {
        console.log("err:", err);
      });
  };
  const gotoProfile = () => {
    navigate("/dashboard/profile");
  };
  return (
    <>
      {/* Custom BannerCard Start */}
      {/* Custom BannerCard for Desktop */}
      <Hidden smDown>
        <Grid container spacing={2} style={{ marginTop: "10px" }}>
          <Grid
            item
            xs={12}
            style={{
              borderRadius: "10px",
              backgroundImage: "radial-gradient(circle, #7fbcca, #44d3f2)",
              display: "flex",
              marginLeft: "15px",
            }}
          >
            <Grid item xs={12} sm={6} lg={6}>
              <Box
                style={{
                  height: "30vh",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={credilio}
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                  alt="Credit Card"
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={6}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Box style={{ padding: "20px" }}>
                <Typography
                  style={{
                    color: "#fff",
                    fontSize: "30px",
                    lineHeight: "30px",
                    marginBottom: "10px",
                    fontFamily: "CustomFontMedium",
                  }}
                >
                  Explore Benefits of Good credit score
                </Typography>
                <Typography
                  style={{
                    color: "black",
                    lineHeight: "16px",
                    fontSize: ".9rem",
                    fontFamily: "CustomFontMedium",
                    marginTop: "15px",
                  }}
                >
                  Discover the best credit cards for good credit scores! Enjoy
                  exclusive rewards, low interest rates, premium perks, enhanced
                  security, and flexibility. Apply now to unlock unmatched
                  benefits and start saving today!
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    marginTop: "40px",
                    width: "100%",
                    backgroundColor: "#EF8062",
                    transition: "background-color .3s ease",
                    color: "#fff",
                    fontWeight: "600",
                    "&:hover": {
                      backgroundColor: "#2196F3",
                      color: "#fff",
                    },
                    fontFamily: "CustomFontMedium",
                  }}
                  onClick={handleCredilioPlus}
                >
                  Explore Offer
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      {/* Custom BannerCard for Mobile */}
      <Hidden mdUp smUp>
        <Grid container spacing={2} style={{ marginTop: "10px" }}>
          <Grid
            item
            xs={12}
            style={{
              borderRadius: "10px",
              backgroundImage: "radial-gradient(circle, #7fbcca, #44d3f2)",
              display: "flex",
              marginLeft: "15px",
              flexDirection: "column",
            }}
          >
            <Grid item xs={12}>
              <Box
                style={{
                  height: "30vh",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={credilio}
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                  alt="Credit Card"
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box style={{ padding: "20px" }}>
                <Typography
                  style={{
                    color: "#fff",
                    fontSize: "1.3rem",
                    lineHeight: "25px",
                    marginBottom: "10px",
                  }}
                >
                  Explore Benefits of Good credit score
                </Typography>
                <Typography
                  style={{
                    color: "black",
                    lineHeight: "16px",
                    fontSize: ".9rem",
                  }}
                >
                  Discover the best credit cards for good credit scores! Enjoy
                  exclusive rewards, low interest rates, premium perks, enhanced
                  security, and flexibility. Apply now to unlock unmatched
                  benefits and start saving today!
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    marginTop: "20px",
                    width: "100%",
                    backgroundColor: "#EF8062",
                    transition: "background-color .3s ease",
                    color: "#fff",
                    fontWeight: "550",
                    "&:hover": {
                      backgroundColor: "#2196F3",
                      color: "#fff",
                    },
                  }}
                  onClick={handleCredilioPlus}
                >
                  Explore Offer
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      {/* Custom BannerCard end */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "160px",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            // width: "640px",
            width: {
              lg: "40%",
              sm: "50%",
              xs: "90%",
            },
            // padding: "15px",
          }}
        >
          <div style={{display:"flex",width:"100%", justifyContent:"end"}}>
         
          <CancelIcon
            style={{ color: "red", fontSize: "25px",cursor:"pointer" }}
            onClick={handleCloseModal}
          />
           </div>

          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            className="profile-modal-1"
          >
            Your profile is not completed, Please Complete your profile
          </Typography>
          <button            
            onClick={gotoProfile}
            className="profile-modal-button-1"
          >
            Profile
          </button>
        </Container>
      </Modal>
    </>
  );
}

export default CredilioCardPlus;
