import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Tooltip,
  useMediaQuery,
  Popover,
} from "@mui/material";
import axiosInstance from "../service/axiosInstance";
import { toast } from "react-toastify";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import experianLogo from "../assets/imgs/Experian_logo.webp";
import { useNavigate } from "react-router-dom";
import CredilioCard from "./CredilioCard";
import CredilioCardPlus from "./CredilioCardPlus";
import TabsCoponent from "../DashboardComponents/TabsCoponent";
import Loading from "../Components/Loading";
import ScoreChart from "./ScoreChart";
import SomethingWrong from "./SomethingWrong";
import API_ENDPOINTS from "../service/apiEndpoints";
import apiClient from "../service/apiClient";
import apiService from "../service/apiService";
import { USER_REPORT } from "../service/ApiList";
import CryptoJS from "crypto-js";

function CreditReport() {
  const isMobile = useMediaQuery("(max-width:1100px)");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [experianData, setExperianData] = useState("");
  const [anchorEl, setAnchorEl] = useState(null); // State for popover
  const [isError, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem("TDFToken");
    const userConfirms = "loginConfirm"; 
    localStorage.setItem("loginConfirm", userConfirms);
    // axiosInstance
    //   .get("/tdf/api/user/getReport", {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   })
    //   .then((res) => {

    //     setLoading(false);
    //     setExperianData(res?.data?.myReport);
    //     toast.success(res?.data?.msg);
    //     localStorage.setItem("phoneNumber", res?.data?.myReport?.mobile)
    //     setError(false)
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //     setLoading(false);
    //     if (err?.response?.status === 401) {
    //       // Clear token and redirect on 401 Unauthorized
    //       localStorage.removeItem("TDFToken");
    //       localStorage.removeItem("TDFToken");
    //       window.location.href = '/'; // Redirect to home page
    //     } else {
    //       // toast.error(err?.response?.data?.message || err?.message);
    //       setError(true)
    //     }
    //   });
    const SECRET_KEY = "secret_key_for_tdf_developers";
    USER_REPORT((res, err) => {
      if (res?.status == 200) {
        setLoading(false);
        setExperianData(res?.data?.myReport);
        toast.success(res?.data?.msg);
        // localStorage.setItem("phoneNumber", res?.data?.myReport?.mobile);

        // Data encryption before storing in localStorage
        const encryptedMobile = CryptoJS.AES.encrypt(
          res?.data?.myReport?.mobile,
          SECRET_KEY
        ).toString();

        localStorage.setItem("phoneNumber", encryptedMobile);
        setError(false);
      }
      // else{
      //   console.log(err)
      //   setLoading(false);
      //   if (err?.response?.status === 401) {
      //     // Clear token and redirect on 401 Unauthorized
      //     localStorage.removeItem("TDFToken");
      //     localStorage.removeItem("TDFToken");
      //     window.location.href = '/'; // Redirect to home page
      //   } else {
      //     // toast.error(err?.response?.data?.message || err?.message);
      //     setError(true)

      //   }

      // }
      else if ([201, 301, 400, 401, 403, 404, 500].includes(res?.status)) {
        const errorMessages = {
          201: "Something went wrong. Please try again.",
          301: "Something went wrong. Please try again.",
          400: "Bad request. Please check your input and try again.",
          401: "Unauthorized. Please log in again.",
          403: "Access denied. You do not have permission to view this.",
          404: "Requested resource not found.",
          500: "Server error. Please try again later.",
        };
        toast.error(errorMessages[res?.status] || "An unknown error occurred.");
        setLoading(false);
        setError(false);
      }
    });
  }, []);

  const handleLogout = () => {
    localStorage.clear("experianData");
    localStorage.clear("TDFToken");
    localStorage.clear("loginConfirm");
    navigate("/");
  };

  const handlegoProfile = () => {
    navigate("/dashboard/profile");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); // Open popover
  };

  const handleClose = () => {
    setAnchorEl(null); // Close popover
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    const ErrorHandleing = () => {
      if (isError) {
        toast.error("Something went wrong. Please try again.");
        setTimeout(() => {
          handleLogout();
        }, 5000);
      }
    };
    ErrorHandleing();
  }, [isError]);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Box sx={{ padding: { xs: "20px", lg: "32px" } }}>
            {/* top nav bar start */}
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Typography
                  sx={{
                    color: "#044a67",
                    fontSize: { xs: "18px", sm: "20px", lg: "20px" },
                    fontFamily: "CustomFontMedium",
                  }}
                >
                  Hi {experianData?.name} {experianData?.surName}!
                </Typography>
                <Typography
                  sx={{
                    color: "#044a67",
                    fontSize: { xs: "18px", sm: "20px", lg: "20px" },
                    fontFamily: "CustomFontMedium",
                  }}
                >
                  It's good to see you again.
                </Typography>
              </Box>

              {isMobile ? (
                <></>
              ) : (
                <>
                  <Box style={{ display: "flex" }}>
                    <SettingsIcon
                      style={{ marginRight: "20px", cursor: "pointer" }}
                      onClick={handleClick} // Open the popover on click
                    />

                    <Tooltip title="Logout">
                      <LogoutIcon
                        onClick={handleLogout}
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Box>
                </>
              )}
              {/* <Box style={{ display: "flex" }}>
              <Tooltip title="Profile">
                <SettingsIcon
                  style={{ marginRight: "20px", cursor: "pointer" }}
                  onClick={handleClick} // Open the popover on click
                />
              </Tooltip>
              <Tooltip title="Logout">
                <LogoutIcon
                  onClick={handleLogout}
                  style={{ cursor: "pointer" }}
                />
              </Tooltip>
            </Box> */}
            </Box>

            {/* Popover for Settings */}
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Box p={2}>
                <Typography
                  onClick={handlegoProfile}
                  style={{ cursor: "pointer" }}
                >
                  Profile
                </Typography>
              </Box>
            </Popover>

            {/* Content Section */}
            <Box style={{ marginTop: "20px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} lg={3}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <GaugeChart score={"300"} /> */}
                    <ScoreChart
                      // score={"750"}
                      score={experianData?.score?.score}
                    />
                  </Box>
                  {isMobile ? (
                    <>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "12px",
                            fontFamily: "CustomFontMedium",
                          }}
                        >
                          Powered By
                        </Typography>
                        <img src={experianLogo} alt="exprian" width="100" />
                      </Box>
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  lg={9}
                  // this line can create problem in mobile divice responsiveness start
                  sx={{ display: { lg: "flex" }, alignItems: { lg: "center" } }}
                  // this line can create problem in mobile divice responsiveness end
                >
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "100%", md: "95%", lg: "95%" },
                    }}
                  >
                    <Typography
                      sx={{
                        // marginTop: {
                        //   lg: "60px",
                        //   sm: "60px",
                        // },
                        fontFamily: "CustomFontMedium",
                        textAlign: "justify",
                        fontSize: "16px",
                        lineHeight: "30px",
                        fontWeight: "500px",
                      }}
                    >
                      {experianData?.score?.message}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              {isMobile ? (
                <></>
              ) : (
                <>
                  <Grid container>
                    <Grid item xs={12} sm={6} lg={3}>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "12px",
                            fontFamily: "CustomFontMedium",
                          }}
                        >
                          Powered By
                        </Typography>
                        <img src={experianLogo} alt="exprian" width="100" />
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>

            <Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Box style={{ marginTop: "20px" }}>
                  <TabsCoponent userData={experianData} />
                </Box>
              </Grid>
            </Grid>
            {experianData?.score?.score <= 650 ? (
              <Box style={{ marginTop: "25px" }}>
                <CredilioCard />
              </Box>
            ) : (
              <CredilioCardPlus />
            )}
          </Box>
        </>
      )}
    </>
  );
}

export default CreditReport;
