import React from "react";
import {
    Box,
    Grid,
    Typography,
} from "@mui/material";
import TDF1 from "../assets/imgs/TDF1.webp";
import TDF2 from "../assets/imgs/TDF2.webp";
import TDF3 from "../assets/imgs/TDF3.webp";
import TDF4 from "../assets/imgs/TDF4.png";
function Cards() {
    const cardData = [
        {
            image: TDF1, // Replace with actual image paths
            title: "We Are Here For YOU",
            text: "Unlike a collection agency that represents the bank, we represent YOU.",
        },
        {
            image: TDF2,
            title: "Single Solution For All Credit Issues",
            text: "We offer a holistic, overall solution for resolving multiple lines of default.",
        },
        {
            image: TDF3,
            title: "Leave The Paperwork To Us",
            text: "We manage all correspondence and documentation with your lenders on your behalf.",
        },
        {
            image: TDF4,
            title: "Personalized Solutions",
            text: "Custom-made suggestions to build your credit health",
        },
    ];
    return (
        <>
            <Grid container spacing={2} 
            // sx={{ padding: { xs: "10px", lg: "20px 83px 40px" } }} 

            sx={{ padding: { xs: "10px", lg: "20px 0 40px" }, marginTop:{xs:"20px", md:"0"} }} 

            >
                {cardData.map((card, index) => (
                    <Grid item xs={12} sm={6} lg={3} md={6} key={index}>
                        <Box sx={{ display: "flex", justifyContent:'space-between', background: "#fff", padding:{xs:"10px 20px", md:"20x",lg:"20px"} , maxWidth: "100%", minHeight: "120px", maxHeight: "120px", borderRadius: "15px", alignItems: "center", boxShadow: "0 2px 4px 0 rgba(5,47,95,.04),0 12px 16px 0 rgba(52,105,203,.12)", border: "1px solid rgba(235,239,247,.8)" }}>
                            <Box>
                                <Box>
                                    <Typography sx={{ fontSize: "16px", fontWeight: "500", fontFamily: "CustomFont", }}>
                                        {card.title}
                                    </Typography> </Box>
                                <Box sx={{display:"flex"}}>
                                    <Typography sx={{ fontSize: "12px", fontWeight: "400", fontFamily: "CustomFont" }}>
                                        {card.text}

                                    </Typography>
                                    {/* <Box>
                                        <img src={card.image} />
                                    </Box> */}
                                </Box>
                            </Box>
                            <Box sx={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                                <img src={card.image}  width={"100%"}/>
                            </Box>
                        </Box>
                    </Grid>
                ))}

            </Grid>
        </>
    );
}

export default Cards;
