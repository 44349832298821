import React, { useEffect, useState } from "react";
import banner from "../assets/imgs/TDF-full-img.webp";
import { GiCheckMark } from "react-icons/gi";
import {
  Box,
  Button,
  Grid,
  Typography,
  Modal,
  Container,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { MuiOtpInput } from "mui-one-time-password-input";
import axiosInstance from "../service/axiosInstance";
import Loading from "../Components/Loading";
import { useLocation } from "react-router-dom";
import HeaderComponent from "../Components/HeaderCoponent";
import { useMediaQuery } from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  fetchReportData,
  postCreditOtp,
  tdfFetchReport,
} from "../service/ApiList";
import encryptionValues from "../utils/encryptionValues";
function OtpVerification({ UTMData, setStateLifitingUp }) {
  const isMobile = useMediaQuery("(max-width:600px)");
  const location = useLocation();
  // timmer funcality comment for some times
  const [timer, setTimer] = useState(60);
  // const [buttonTimer, setButtonTimer] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const updatedFormData = location?.state?.updatedFormData;
  const pathname = location?.pathname;

  const navigate = useNavigate();

  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [experian, setExperian] = useState({});
  // State for text visibility
  const [showFirstSet, setShowFirstSet] = useState(true);
  const [buttonTimer, setButtonTimer] = useState(10);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (buttonTimer > 0 && openModal) {
      const countdown = setInterval(() => {
        setButtonTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(countdown);
    } else if (buttonTimer === 0 && openModal) {
      navigate("/");
      localStorage.clear()
    }
  }, [buttonTimer, openModal]);

  //Otp input validation start
  function matchIsString(text) {
    return typeof text === "string";
  }
  function matchIsNumeric(text) {
    const isNumber = typeof text === "number";
    const isString = matchIsString(text);
    return (isNumber || (isString && text !== "")) && !isNaN(Number(text));
  }
  const validateChar = (value, index) => {
    return matchIsNumeric(value);
  };
  //Otp input validation end
  const handleChangeOtp = (newValue) => {
    setOtp(newValue);
  };
  const handleSubmitCriditOtp = (e) => {
    const newOtp = { otp: otp };
    setLoading(true);
    e.preventDefault();
  

    postCreditOtp(newOtp, (res, err) => {
      if (res) {
        setLoading(false);
        setExperian(res?.data);
        // FetchReport API
        // if (!res?.data?.openForm) {
        //   navigate("/");
        // }

        if (res?.data?.myReport) {
          localStorage.setItem("StatusLS","BUREAU_FETCH")  
        // encryptionValues({ key: "StatusLS", value: "BUREAU_FETCH" });

          navigate("/dashboard/reports", { state: { data: res?.data } });
          // toast.success("Login Successfully!");              

        }

        if (res?.data?.error) {
          if (res?.data?.error !== "consumer record not found") {
            toast.error(res?.data?.error);
          }

          if (
            res?.data?.error === "consumer record not found" &&
            res?.data?.openForm
          ) {
            if (
              res?.data?.openForm ||
              res?.data?.error === "OTP validation failed, OTP is not match"
            ) {
              navigate("/login/experianfullform");
              localStorage.setItem("StatusLS","ENHANCED_FETCH_REPORT_OTP_VALIDATED")     
              // encryptionValues({ key: "StatusLS", value: "ENHANCED_FETCH_REPORT_OTP_VALIDATED" });

            } 
            // else {
            //   navigate("/");
            //   toast.error(res?.data?.error);
            // }
             if(res?.data?.openForm === true && pathname?.includes("/login/experianfullform") && res?.data?.error === "consumer record not found" ){
              setOpenModal(true);            
            }
          } else if (
            res?.data?.openForm === false &&
            res?.data?.error === "consumer record not found"
          ) {
            // navigate("/");
            setOpenModal(true);
            // toast.error(res?.data?.error);
          } else if (
            res?.data?.openForm === false &&
            res?.data?.error ===
              "OTP validation already tried,register consumer again for new OTP"
          ) {
            // toast.error(res?.data?.error);
            setStateLifitingUp(true);
          } else if (
            res?.data?.openForm === true &&
            res?.data?.error ===
              "OTP validation already tried,register consumer again for new OTP"
          ) {
            // navigate("/login");
            setStateLifitingUp(true);
            // toast.error(res?.data?.error);
          }
          else if(res?.data?.openForm === true && pathname?.includes("/login/experianfullform") && res?.data?.error === "consumer record not found" ){
            setOpenModal(true);            
          }
        } else {
          // toast.success("OTP Validate Successfully!");
        }
      } else {
        console.log("err:", err);
        setLoading(false);
        toast.error(err?.message);
      }
    });
  };
  // Toggle between first set and second set of texts
  useEffect(() => {
    const interval = setInterval(() => {
      setShowFirstSet((prevShowFirstSet) => !prevShowFirstSet);
    }, 4000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);
  const handleResendOtp = () => {
    setOtp("");
    const getLocalStorage = localStorage.getItem("TDFToken");
    const token = getLocalStorage;
    console.log("UTMData:", UTMData);
    // ehache api
    if (pathname?.includes("/login/user")) {
      fetchReportData(UTMData, (res, err) => {
        if (res) {
          if (res?.data?.msg) toast.success(res?.data?.msg);
        } else {
        }
      });
    }
    // normal fetch with full details
    if (pathname?.includes("/login/experianfullform")) {
      tdfFetchReport(UTMData, (res, err) => {
        if (res) {
          if (res?.data?.msg) toast.success(res?.data?.msg);
        } else {
        }
      });
    }

    setTimer(60);
    setIsButtonDisabled(true);
  };
  // Countdown Timer Effect
  useEffect(() => {
    let countdown;
    if (timer > 0 && isButtonDisabled) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsButtonDisabled(false);
      clearInterval(countdown);
    }
    return () => clearInterval(countdown);
  }, [timer, isButtonDisabled]);

  const handlenavigateHome = () => {
    navigate("/");
    localStorage.clear();
  };

  return (
    <>
      <>
        {loading && (
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(252, 240, 240, 0.5)", // Semi-transparent background
              zIndex: 1300, // Higher than other elements
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={60} sx={{ color: "#ffffff" }} />
          </Box>
        )}
        <Box
          style={{
            background: "#044A67",
            width: "100%",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <HeaderComponent />

          <Grid container sx={{ padding: "20px" }}>
            <Grid
              item
              xs={12}
              sm={12}
              lg={6}
              md={6}
              sx={{
                order: { sm: 1, xs: 2, md: 1, lg: 1 },
                marginTop: { xs: "25px", sm: "0px" },
                paddingBottom: { xs: "60px", sm: "0px" },
              }}
            >
              <img
                src={banner}
                alt="pullLogo"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  width: "80%",
                }}
              />
              <Box
                style={{
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  lineHeight: "30px",
                  fontSize: "24px",
                  marginTop: "10px",
                  textAlign: "center",
                  fontFamily: "CustomFontMedium",
                }}
              >
                When you share your details, you’ll get:
              </Box>
              <Box
                sx={{
                  marginTop: "10px",
                  width: { lg: "50%", sm: "50%" },
                  marginLeft: { lg: "20%", sm: "20%" },
                  padding: "10px",
                  height: "30vh",
                }}
              >
                {showFirstSet ? (
                  <>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <GiCheckMark
                        style={{
                          color: "#ffff",
                          marginTop: "5px",
                          fontSize: "12px",
                        }}
                      />
                      <Typography
                        style={{
                          color: "#ffff",
                          marginLeft: "10px",
                          fontFamily: "CustomFontMedium",
                        }}
                      >
                        Free Experian Credit Score and Report
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginTop: "10px",
                      }}
                    >
                      <GiCheckMark
                        style={{ color: "#ffff", marginTop: "5px" }}
                      />
                      <Typography
                        style={{
                          color: "#ffff",
                          marginLeft: "10px",
                          fontFamily: "CustomFontMedium",
                        }}
                      >
                        Monitor your score improvements - Report and Score
                        Refreshed Every 45 Days, on Sign In
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginTop: "10px",
                      }}
                    >
                      <GiCheckMark
                        style={{ color: "#ffff", marginTop: "5px" }}
                      />
                      <Typography
                        style={{
                          color: "#ffff",
                          marginLeft: "10px",
                          fontFamily: "CustomFontMedium",
                        }}
                      >
                        Complimentary Alerts and Recommendations from Due
                        Factory
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginTop: "10px",
                      }}
                    >
                      <GiCheckMark
                        style={{ color: "#ffff", marginTop: "5px" }}
                      />
                      <Typography
                        style={{
                          color: "#ffff",
                          marginLeft: "10px",
                          fontFamily: "CustomFontMedium",
                        }}
                      >
                        We help you prioritize your repayments based on deep
                        data insights.
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginTop: "10px",
                      }}
                    >
                      <GiCheckMark
                        style={{ color: "#ffff", marginTop: "5px" }}
                      />
                      <Typography
                        style={{
                          color: "#ffff",
                          marginLeft: "10px",
                          fontFamily: "CustomFontMedium",
                        }}
                      >
                        Take decisions which change your future for the better.
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              lg={6}
              md={6}
              sx={{
                padding: { xs: "0px", sm: "0px", lg: "40px", md: "40px" },

                display: "flex",
                alignItems: "center",
                order: { sm: 2, xs: 1, md: 2, lg: 2 },
              }}
            >
              <Box
                sx={{
                  border: "2px solid whitesmoke",
                  width: { sm: "100%", lg: "100%" },
                  background: "#FFFFFF",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "5px",
                  padding: { xs: "10px", sm: "10px", lg: "20px", md: "20px" },
                }}
              >
                <Box>
                  <form onSubmit={handleSubmitCriditOtp}>
                    <Box
                      sx={{
                        padding: {
                          xs: "0 10px",
                          sm: "0 10px",
                          lg: "none",
                          md: "none",
                        },
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          fontSize: "24px",
                          fontWeight: "400",
                          fontFamily: "CustomFontMedium",
                        }}
                      >
                        OTP Verification
                      </Typography>
                      <Typography
                        style={{
                          color: "rgba(0,0,0,.6)",
                          fontFamily: "CustomFontMedium",
                        }}
                      >
                        Enter the OTP you received on your mobile number
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        padding: {
                          xs: "0 5px",
                          sm: "0 10px",
                          lg: "none",
                          md: "none",
                        },
                      }}
                    >
                      <MuiOtpInput
                        value={otp}
                        onChange={handleChangeOtp}
                        TextFieldsProps={{
                          placeholder: "-",
                          size: "medium",
                          inputProps: {
                            style: {
                              fontWeight: "bold",
                            },
                            inputMode: "numeric",
                          },
                        }}
                        gap={isMobile ? 1 : 2} // Gap 1 for mobile, 2 for larger screens
                        validateChar={validateChar}
                        style={{ marginTop: "20px" }}
                        length={6}
                      />
                    </Box>

                    <Box style={{ marginTop: "20px" }}>
                      <Button
                        variant="contained"
                        type="submit"
                        style={{
                          background: otp.length == 6 ? "#044A67" : "#d3d3d3",
                          color:
                            otp.length == 6 ? "#FFFFFF" : "rgba(0, 0, 0, .26)",
                          width: "100%",
                          textTransform: "capitalize",
                          marginTop: "25px",
                        }}
                        disabled={otp.length !== 6 || loading}
                      >
                        <>Submit</>
                      </Button>
                    </Box>
                    {/* {experian?.error === "OTP validation already tried,register consumer again for new OTP" || experian?.error === "OTP validation failed, OTP is not match" ? ( */}
                    <>
                      <Button
                        variant="text"
                        size="small"
                        sx={{
                          textTransform: "capitalize",
                          color: isButtonDisabled ? "yellow" : "#044a67",
                          marginTop: "10px",
                        }}
                        onClick={handleResendOtp}
                        disabled={isButtonDisabled}
                      >
                        Resend OTP
                        {isButtonDisabled ? `(${timer}s)` : ""}
                      </Button>
                    </>
                    {/* ) : ""} */}
                  </form>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Modal
          open={openModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
              // width: "383px",

              width: {
                lg: "383px",
                sm: "383px",
                xs: "90%",
              },
            }}
          >
            <Typography
              id="modal-title"
              variant="h6"
              component="h2"
              style={{
                fontSize: "16px",
                fontWeight: "500",
                fontFamily: "CustomFontMedium",
              }}
            >
              Credit Report Not Found !
            </Typography>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  background: "#1cb0e7",
                  color: "white",
                  borderRadius: "10px",
                  fontWeight: "500",
                  fontFamily: "CustomFontMedium",
                  textTransform: "capitalize",
                }}
                onClick={handlenavigateHome}
              >
                <Box style={{ display: "flex", alignItems: "center" }}>
                  Redirecting to home {buttonTimer} seconds...
                </Box>
              </Button>
            </Box>
          </Container>
        </Modal>
      </>
    </>
  );
}
export default OtpVerification;
