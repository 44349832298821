import React from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TDFControl from "../assets/homePage2/control.webp";

function AdvertiseSection() {
  const navigate = useNavigate();
  const gotoLogin = () => {
    navigate("/login");
  };
  return (
    <>
      <Box sx={{ padding: { xs: "none", sm: "none", lg: "50px 83px 0" } }}>
        <Grid container sx={{ paddingTop: "10px" }}>
          <Grid
            item
            xs={12}
            sm={12}
            lg={6}
            md={6}
            sx={{
              display: { xs: "block", sm: "block", lg: "flex", md: "flex" },
              padding: { xs: "20px", sm: "20px", lg: "20px", md: "20px" },
              alignItems: { lg: "center", md: "center" },
              background: "#044a67",
              borderRadius: {
                xs: "10px",
                sm: "10px",
                lg: "10px 0 0 10px",
                md: "10px 0 0 10px",
              },
            }}
          >
            <Box
              sx={{
                marginLeft: {
                  xs: "none",
                  sm: "none",
                  lg: "40px",
                
                },
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontFamily: "CustomFontMedium",
                    color: "#fff",
                    fontSize: {
                      xs: "27px",
                      sm: "35px",
                      lg: "50px",
                      md: "50px",
                    },
                    textAlign: {
                      xs: "center",
                      sm: "center",
                      lg: "start",
                      md: "start",
                    },
                  }}
                >
                  Take Control Of Your
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "CustomFontMedium",
                    color: "#fff",
                    fontSize: {
                      xs: "27px",
                      sm: "35px",
                      lg: "50px",
                      md: "50px",
                    },
                    textAlign: {
                      xs: "center",
                      sm: "center",
                      lg: "start",
                      md: "start",
                    },
                  }}
                >
                  Credit Health Today
                </Typography>
              </Box>

              <Box
                sx={{
                  textAlign: {
                    xs: "center",
                    sm: "center",
                    lg: "justify",
                    md: "justify",
                  },
                }}
              >
                <Button
                  sx={{
                    "&:hover": {
                      background: "#f0f0f0",
                      color: "#333",
                    },
                    textTransform: "capitalize",
                    background: "#fff",
                    color: "black",
                    width: {
                      xs: "none",
                      sm: "210px",
                      lg: "210px",
                      md: "210px",
                    },
                    padding: {
                      xs: "0 25px",
                      sm: "5px 30px",
                      lg: "5px 30px",
                      md: "5px 30px",
                    },
                    fontWeight: { xs: "400", sm: "500", lg: "500", md: "500" },
                    marginTop: "10px",
                    borderRadius: "35px",
                    fontSize: "20px",
                  }}
                  size="large"
                  onClick={gotoLogin}
                >
                  Register Now
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={0}
            sm={6}
            lg={6}
            md={6}
            sx={{
              display: { xs: "none", sm: "none", lg: "block", md: "block" },
            }}
          >
            <img
              src={TDFControl}
              alt="TDF"
              width={"100%"}
              height={"100%"}
              style={{ borderRadius: "0 10px 10px 0" }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default AdvertiseSection;
