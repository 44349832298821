import React, { useEffect, useRef } from 'react';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const EnquiryVelocity = () => {
    const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
    const chartRef = useRef(null);

    const data = {
        labels: quarters,
        datasets: [
            {
                label: '',
                data: [
                    { x: 1, y: 2, r: 15 },
                    { x: 2, y: 4, r: 10 },
                    { x: 3, y: 4, r: 20 },
                    { x: 4, y: 2, r: 25 },
                ],
                borderColor: 'rgba(255, 99, 132, 1)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false, // Hide the legend
            },
            title: {
                display: true,
                text: '',
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: '',
                },
                ticks: {
                    callback: function (value) {
                        return quarters[value - 1];
                    },
                },
                min: 1,
                max: 4,
            },
            y: {
                title: {
                    display: true,
                    text: '',
                },
                min: 0,
                max: 5,
            },
        },
    };

    useEffect(() => {
        const ctx = document.getElementById('bubbleChartEnquiryVel').getContext('2d');

        // Destroy the previous chart instance if it exists
        if (chartRef.current) {
            chartRef.current.destroy();
        }

        // Create a new chart instance and store it in the ref
        chartRef.current = new Chart(ctx, {
            type: 'bubble',
            data: data,
            options: options,
        });

        // Cleanup function to destroy the chart when the component unmounts
        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
            }
        };
    }, []);

    return (
        <div className='enquiry-vel-main'>
            <div className="enquiry-vel-main-inner">
                <div className="enquiry-vel-main-inner-head">
                    <span>Enquiry Velocity</span>
                </div>
                <p>
                    Financial reliability measures an individual’s or entity’s commitment
                    to meeting debt obligations on time, based on their history of
                    payments, income consistency, and financial responsibility.
                    Tracking payment discipline helps lenders gauge the risk of
                    delinquency, ensuring accounts and loan histories remain in
                    good standing. A strong financial track record demonstrates
                    reliability and increases eligibility for loans and favorable
                    interest rates, while lapses in payment discipline may signal
                    potential risks.
                </p>
                <div className='enquiry-vel-main-inner-graph'>
                    <div style={{ width: '70%', height: '400px' }}>
                        <canvas id="bubbleChartEnquiryVel"></canvas>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EnquiryVelocity;
