import React, { useEffect, useRef, useState, } from "react";
import "./PremiumDashboard.css";
import PremiumReport from "../CentralMenu/PremiumReport";
import CreditMix from "./CreditMix";
import Loading from "../Components/Loading";
import HeaderComponent from "../Components/HeaderCoponent";
import FotterComponent from "../Components/FotterComponent";
import CreditMixNew from "../CentralMenu/CreditMixNew";
import SomethingWrong from "./SomethingWrong";
import { GET_INSIGHTS } from "../service/ApiList";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function PremiumDashboard() {
  const [activeTab, setActiveTab] = useState("All");
  const [insightData, setInsightData] = useState("");
  const [loading, setLoading] = useState(false);
  const [isError,setIsError]=useState(false)

  const navigate = useNavigate()

  const topDivRef = useRef(null); // Ref for "top-div-pre"
  const lastScrollY = useRef(0); // Track the last scroll position



  const handleSwitch = (tab) => {
    setActiveTab(tab);
  };
  const insightReportApi = () => {
    setLoading(true);  
    GET_INSIGHTS({},
      (res) => {
        if (res){
          setInsightData(res?.data?.response);
          setLoading(false);
          setIsError(false)

        }
        else{
          setLoading(false);
          setIsError(true)

        }

      }

    )  



  };
  const getLeftPosition = () => {
    switch (activeTab) {
      case "All":
        return "1%";
      case "Good":
        return "33.33%";
      case "Bad":
        return "65.66%";
      default:
        return "0%";
    }
  };
  const renderContent = () => {
    switch (activeTab) {
      case "All":
        return <CreditMix creditMixdata={insightData?.insightCreditMix?.ALL} />
      case "Good":
        return insightData?.insightCreditMix?.GOOD?.totalAccounts >= 1 ? (
          <>
            <CreditMix creditMixdata={insightData?.insightCreditMix?.GOOD} />
          </>
        ) : (
          <>
            <div style={{ height: "50vh" }}>
              <div
                style={{
                  height: "210px",
                  background: "#ECF0F6",
                  borderRadius: "24px",
                  padding: "10px 20px",
                  margin: "10px"
                }}
              >
                <p className="bad-account-msg">
                  😞 “It looks like there are some challenges with your current
                  loans. But remember, it’s never too late to make a positive
                  change. We’re here to support you on your path to financial
                  stability.”
                </p>
              </div>
            </div>
          </>
        )
      case "Bad":
        return insightData?.insightCreditMix?.BAD?.totalAccounts >= 1 ? (
          <>
            <CreditMix creditMixdata={insightData?.insightCreditMix?.BAD} />
          </>
        ) : (
          <>
            <div style={{ height: "50vh" }}>
              <div
                style={{
                  height: "210px",
                  background: "#ECF0F6",
                  borderRadius: "24px",
                  padding: "10px 20px",
                  margin: "10px"
                }}
              >
                <p className="bad-account-msg">
                  🌟 “Impressive! Zero bad loans on your record—your financial
                  game is on point! Keep it going strong!”
                </p>
              </div>
            </div>
          </>
        );

      default:
        return null;
    }
  };
  useEffect(() => {
    insightReportApi()
  }, []) 
  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth <= 1350) {
        return;
      }   
      if (topDivRef.current) {
        
        const rect = topDivRef.current.getBoundingClientRect();

        // Detect scroll direction
        const currentScrollY = window.scrollY;
        const isScrollingDown = currentScrollY > lastScrollY.current;
        lastScrollY.current = currentScrollY;

        // Only trigger the effect if scrolling down and the element is partially visible
        if (isScrollingDown && rect.top < window.innerHeight && rect.bottom > 0) {
          // Calculate the scroll offset to bring the bottom of "top-div-pre" into view
          const scrollOffset =
            rect.bottom - window.innerHeight > 0
              ? rect.bottom - window.innerHeight // Bottom isn't fully in view, scroll to bring it in
              : 0; // Already fully in view, no need to scroll

          window.scrollBy({
            top: scrollOffset,
            behavior: "smooth",
          });
        }
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleLogout = () => {
    localStorage.clear("TDFToken");
    localStorage.clear("loginConfirm");
    navigate("/");
  };
  const ErrorHandleing = () => {
    if (isError) {
      toast.error("Something went wrong. Please try again. ");
      setTimeout(()=>{
        handleLogout()
      },5000)
    }
  };
  useEffect(() => {   
    ErrorHandleing();
  }, [isError]);
  return (
    <>
      {loading ? (
        <>
          <Loading />
        </>
      ) : (
        <>      

          <div style={{ paddingBottom: "1.5rem" }}>
            <HeaderComponent />
          </div>

          {/* <CreditMixNew  data={insightData}/> */}
          <PremiumReport data={insightData} />
          <div style={{ background: '#ECF0F6' }}>

            <div className="top-div-pre"  ref={topDivRef} >

              <div className="parentDiv1">
                <div
                 className="credit-mix-desktop-div-1"
                >
                  <span>Credit Mix</span>
                </div>
                <div className="switch-container-top">
                  <div className="switch-container">
                    <div
                      className="switch-background"
                      style={{ left: getLeftPosition() }}
                    ></div>
                    <div
                      className={`switch-btn ${activeTab === "All" ? "active" : ""
                        }`}
                      onClick={() => handleSwitch("All")}
                    >
                      {` All Accounts (${insightData?.insightCreditMix?.ALL?.totalAccounts})`}

                    </div>
                    <div
                      className={`switch-btn ${activeTab === "Good" ? "active" : ""
                        }`}
                      onClick={() => handleSwitch("Good")}
                    >
                      {`Good Accounts (${insightData?.insightCreditMix?.GOOD?.totalAccounts})`}


                    </div>
                    <div
                      className={`switch-btn ${activeTab === "Bad" ? "active" : ""
                        }`}
                      onClick={() => handleSwitch("Bad")}
                    >
                      {`Bad Accounts (${insightData?.insightCreditMix?.BAD?.totalAccounts})`}
                    </div>
                  </div>
                </div>
                <div>{renderContent()}</div>

              </div>


            </div>

          </div>

          <FotterComponent />
        </>
      )}
    </>
  )
}

export default PremiumDashboard;

