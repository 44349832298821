import React from "react";
import img5 from "../assets/imgs/car-5.webp";
import { Box, Grid, Typography } from "@mui/material";
import "./style.css"


const Page5 = () => {
  return (
    <Box>
      <Grid container>
        <Grid
          item
          xs={0}
          sm={6}
          lg={6}
          xl={5}
          sx={{ display: { xs: "none", sm: "block", lg: "block" } }}
        >
          <Box
                         className="desktop-site-div-112"

          >
            <div style={{height:"67%", display:"flex",alignContent:"center", justifyContent:"center"}}>
            
            <img
              src={img5}
              alt="mobile"
              width={"100%"}
              // height="70%"
              className="img-div-112-tdf"
              loading="lazy"
              style={{ minHeight: '395px' }} 
            />
            </div>
          </Box>
        </Grid>
        <Grid
          item
          xs={0}
          sm={6}
          lg={6}
          xl={7}
          sx={{
            display: { xs: "block", sm: "flex", lg: "flex" },
            background: "#bde4f7",justifyContent:"center",alignItems:"center"
          }}
        >
          <Box
            sx={{
              display:"flex",
              flexDirection:"column",
              justifyContent:"center",
              textAlign: "justify",
              padding: "0 100px 30px 45px",
              position: "relative",
            }}
          >
            <Box
                    className="num-of-carousel-div-112"   
            >
              5
            </Box>
            <Typography
              style={{ fontSize: "26px", fontFamily: "CustomFontMedium" }}
            >
              How It Works?
            </Typography>
            <Typography
             className="at-due-factory-text"
            >
              At Due Factory we help you bring up your sub-optimal credit score
              which is a result of your financial history. Missed EMIs on a
              previous loan? Forgot to pay your CC bill months ago? Having
              difficulty in getting a new loan?
            </Typography>
            <Box   className="crasoul-div-heading-112">
              <Typography
                 className="crasoul-text-div-123"
              >
                Make payments
              </Typography>
              <Typography
               className="at-due-factory-text"
              >
                Once You Are All Set To Make Payments , We Help Facilitate All
                Your Payments.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Page5;
