import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "../Pages/Login";
import Sidebar from "../Dashboard/Sidebar";
import CreditReport from "../Dashboard/CreditReport";
import BasicDetails from "../loginFlow/BasicDetails";
import OtpVerification from "../loginFlow/OtpVerification";
import ExperianFullForm from "../loginFlow/ExperianFullForm";
import MySettlement from "../Dashboard/MySettlement";
import ContactusPage from "../Dashboard/ContactusPage";
import FaqComponents from "../Dashboard/FaqComponents";
import Profile from "../Dashboard/Profile";
import AboutUs from "../Pages/AboutUs";
import SettleOffer from "../Dashboard/SettleOffer";
import TermsConditions from "../Pages/TermsConditions";
import HomePage2 from "../Home2/HomePage2";
import RequireAuth, { RequireLS } from "./RequireAuth";
import PageNotFound from "../Pages/PageNotFound";
import HelmetWrapper from "../Dashboard/HelmetWrapper";
import SlashRedirect from "./SlashRedirect";
import PremiumDashboard from "../Dashboard/PremiumDashboard";
import AuthPermission from "../service/Permissions/Auth.per";
import LandingDashboard from "../Pages/Dashboard/LandingDashboard";
import LandingPageAnimation from "../Home2/LandingPageAnimation";
import GrievanceRedressal from "../Pages/GrievanceRedressal/GrievanceRedressal";
import ScrollToTop from "./ScrollToTop";
import RedirectIfAuthenticated from "./RedirectIfAuthenticated"
import RequireStatus from "./RequireStatus";
import decryptValues from "../utils/decryptValues";

function AppRoutes() {

  const allowedStatuses = [
    "ENHANCED_FETCH_REPORT_OTP_VALIDATED",
    "NORMAL_FETCH_REPORT_OTP_SENT",
    "NORMAL_FETCH_REPORT_OTP_VALIDATED"
  ];
  const allowedStatusesNormal = [
    "ENHANCED_FETCH_REPORT_OTP_SENT",
    "REVOKE_CONSENT",
    "BUREAU_DELETED",
    "OTP_VALIDATED",
    null,
  ];

  function StatusProtectedRoute({ children }) {
  // const StatusLS = decryptValues({key:"StatusLS"})

    const StatusLS = localStorage.getItem("StatusLS");
    if (!allowedStatuses.includes(StatusLS)) {
      return <Navigate to="/" replace />;
    }
    return children;
  }

  function StatusProtectedRouteNormal({ children }) {
    let StatusLS = localStorage.getItem("StatusLS");
  // const StatusLS = decryptValues({key:"StatusLS"})

    if (StatusLS === "null") {
      StatusLS = null;
    }
    if (StatusLS == null || allowedStatusesNormal.includes(StatusLS)) {
      return children;
    }

    return <Navigate to="/" replace />;
  }


  return (
    <BrowserRouter>
      <SlashRedirect>
        <ScrollToTop />
        <HelmetWrapper />
        <Routes>
          <Route element={<LandingPageAnimation />} path="/" />
          <Route
            element={
              <RedirectIfAuthenticated>
                <Login />
              </RedirectIfAuthenticated>
            }
            path="/login" />
          <Route path="/login/user" element={<StatusProtectedRouteNormal><RequireAuth><RequireLS ><BasicDetails /></RequireLS></RequireAuth></StatusProtectedRouteNormal>} />

          <Route path="/login/otpverification" element={<RequireAuth><RequireLS ><OtpVerification /></RequireLS></RequireAuth>} />
          <Route path="/login/experianfullform" element={<StatusProtectedRoute><RequireAuth><RequireLS ><ExperianFullForm /></RequireLS></RequireAuth> </StatusProtectedRoute>} />         

          <Route path="/creditdetails" element={<RequireAuth><PremiumDashboard /></RequireAuth>} />


          <Route element={<AboutUs />} path="/about-us" />

          <Route path="/contact-us" element={<ContactusPage />} />
          <Route path="/terms-&-conditions" element={<TermsConditions />} />
          <Route path="/faq" element={<FaqComponents />} />
          <Route path="/*" element={<PageNotFound />} />
          <Route path="/home-desktop" element={<LandingPageAnimation />} />
          <Route path="/grievance-redressal" element={<GrievanceRedressal />} />




          <Route path="/dashboard" element={<RequireStatus> <RequireAuth><Sidebar /></RequireAuth> </RequireStatus>}>
            <Route index element={<Navigate to="reports" replace />} />
            <Route path="reports" element={<CreditReport />} />
            <Route path="settlement" element={<MySettlement />} />
            <Route path="profile" element={<Profile />} />
            <Route path="offer/:id" element={<SettleOffer />} />
            <Route path="terms-and-conditions" element={<TermsConditions />} />

          </Route>


          <Route path='/home' element={<AuthPermission />}>
            <Route path="/home" element={<Navigate to="/home/dashboard" replace />} />
            <Route index path="/home/dashboard" element={<LandingDashboard />} />
            <Route path="/home/dashboard/:id" element={<div><h1>id</h1></div>} />
            <Route path="/home/dashboard-2" element={<div><h1>2</h1></div>} />
            <Route path="/home/dashboard-3" element={<div><h1>3</h1></div>} />
            <Route path="/home/dashboard-4" element={<div><h1>4</h1></div>} />
            <Route path="*" element={<div><h1>page not found</h1></div>} />
          </Route>
        </Routes>
      </SlashRedirect>
    </BrowserRouter>
  );
}

export default AppRoutes;
