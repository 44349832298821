import React,{useState,useEffect} from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Skeleton,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import creditScore from "../assets/imgs/settings.webp";
import { useNavigate } from "react-router-dom";
import Cards from "../Components/Cards";

function WhyDueFactory() {
  const navigate = useNavigate();
  const [imgLoaded, setImgLoaded] = useState(false); // State to track image loading

  const gotoLogin = () => {
    navigate("/login");
  };
  useEffect(() => {
    const img = new Image();
    img.src = creditScore;
    img.onload = () => setImgLoaded(true); // Ensure the image is fully loaded before updating state
  }, []); // Use an empty dependency array to ensure this runs only once

  return (
    <>
      <Box
        sx={{
          // marginTop: {
          //   xs: "30px",
          //   sm: "30px",
          //   lg: "0",
          // },
          marginLeft: {
            xs: "10px",
            lg: "0",
          },
          marginRight: {
            xs: "10px",
            lg: "0",
          },
          padding: {
            xs: "30px 20px",
            lg: "42px 83px",
            sm:"30px 0"
          },

          // background: "#fafafa",
          // height:"100vh"
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={12} lg={12}>
            <Box style={{ textAlign: "center" }}>
              <Typography
                sx={{
                  fontSize: {
                    lg: "40px",
                    sm: "40px",
                    xs: "35px",
                  },
                  fontWeight: {
                    xs: "600",
                    lg: "600",
                    sm: "500",
                  },
                  fontFamily: "CustomFontMedium",
                   color:"#000000",
                }}
               
              >
                Why go with The Due Factory?
              </Typography>
              <Box style={{display:"flex", justifyContent:"center"}}>

                <Box sx={{ display: "flex", justifyContent: "center", width:{xs:"100%", sm:"100%" ,md:"50%"}  }}>
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "18px",
                        sm: "18px",
                        xs: "20px",
                      },
                      maxWidth: {
                        xs: "100%",
                        lg: "100%",
                      },
                      fontFamily: "CustomFontMedium",
                    }}
                  >
                    Due Factory is a credit improvement platform that helps you
                    improve your credit worthiness, and guides you in building
                    strong financial health.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Cards />
        <Box
          style={{ padding: "10px", marginTop: "10px" }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={6}
              lg={6}
              md={6}
              sx={{
                display: { xs: "none", sm: "none", lg: "flex", md: "flex" },
                alignItems: "center",
              }}
            >           
               <Box
                sx={{
                  width: "80%",
                  paddingTop: "56.25%", // Aspect ratio 16:9 (height / width)
                  position: "relative",
                }}
              >
                {/* Skeleton Loader */}
                {!imgLoaded && (
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height="100%"
                    animation="wave" // Added animation
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      borderRadius: "20px",
                    }}
                  />
                )}
                {imgLoaded && (
                  <img
                    src={creditScore}
                    alt="Credit Score Visualization" // Improved alt text
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover", // Ensure the image covers the box
                      borderRadius: "20px",
                    }}
                    loading="lazy" // Lazy loading the image
                  />
                )}
              </Box>
    
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={6}
              md={6}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Box
                sx={{ textAlign: { xs: "center", md: "start" }, marginTop:{xs:"40px", lg:"10px"}  }}
              >
                <Typography
                  sx={{
                    lineHeight: { xs: "none", sm: "55px", lg: "55px" },
                    fontSize: { xs: "35px", sm: "50px", lg: "50px" },
                    fontFamily: "CustomFontMedium",
                    fontWeight: "600",
                  }}
                >
                  Want to improve your credit score but don't know how?
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      lg: "18px",
                      sm: "18px",
                      xs: "20px",
                    },
                    fontWeight: { xs: "600", md: "400" },
                    // marginTop: "10px",
                      marginTop: { xs: "20px", md: "10px" },

                    fontFamily: "CustomFontMedium",
                  }}
                >
                  Due Factory Can Help You Improve Your Credit Score
                </Typography>
                <Button
                  endIcon={<ArrowForwardIcon />}
                  sx={{
                    fontSize: { xs: "13px", sm: "18px", lg: "18px" },
                    background: "#044A67",
                    color: "#fff",
                    marginTop: "20px",
                    textTransform: "capitalize",
                    height: "50px",
                    fontFamily: "CustomFontMedium",
                    borderRadius: "10px",
                    "&:hover": {
                      background: "#044A67", // Change this to the color you want on hover
                      color: "#fff", // Optional: Change text color on hover
                    },
                    borderRadius: "26px",
                    padding: "0 25px",
                  }}
                  onClick={gotoLogin}
                >
                  Let's increase your credit score!
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default WhyDueFactory;
