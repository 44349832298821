import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Modal,
  Container,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import pullimg from "../assets/imgs/pullimg.webp";
import { toast } from "react-toastify";
import { MuiOtpInput } from "mui-one-time-password-input";
import Loading from "../Components/Loading";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import SendIcon from "@mui/icons-material/Send";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../service/axiosInstance";
import HeaderComponent from "../Components/HeaderCoponent";
import TermsConditions from "./TermsConditions";
import "./modal.css";
import apiClient from "../service/apiClient";
import API_ENDPOINTS from "../service/apiEndpoints";
import apiService from "../service/apiService";
import {
  AUTHTOKEN,
  GENERATEOTP,
  profileApiData,
  saveUserDataApi,
  USER_REPORT,
} from "../service/ApiList";
import { useDispatch } from "react-redux";
import encryptionValues from "../utils/encryptionValues";

function Login() {
  const location = useLocation();
  const [formData, setFormData] = useState({
    number: location?.state?.mobile ? location?.state?.mobile : "",
  });
  const navigate = useNavigate();
  const dispath = useDispatch();

  const [res, setRes] = useState("");
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [fade, setFade] = useState(true);
  // timmer funcality comment for some times
  const [timer, setTimer] = useState(60);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [userTrack, UserTrack] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [authRes, setAuthRes] = useState({});
  const [isBlurred, setIsBlurred] = useState(false); // New state to track blur
  const [openModal, setOpenModal] = useState(false);
  const [userStatus, setUserStatus] = useState("");

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  useEffect(() => {
    const mobile = location?.state?.mobile || "";
    const indianPhoneNumberPattern = /^[6-9]\d{9}$/;

    if (indianPhoneNumberPattern.test(mobile)) {
      setIsPhoneNumberValid(true);
    } else {
      setIsPhoneNumberValid(false);
    }

    setFormData({ number: mobile });
    setErrorMessage("");
  }, [location?.state?.mobile]);
  const messages = [
    "Credit Score Pull from us will not lower your Score. And it's Free.",
    "This helps us to validate that the phone number you entered actually belongs to you.",
    "We will register you as a user, by identifying you through this One Time Password. This is important for safety and security reasons.",
  ];

  // For Text Animation
  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentMessageIndex(
          (prevIndex) => (prevIndex + 1) % messages.length
        );
        setFade(true);
      }, 500); // Duration of the fade-out animation
    }, 3000); // Wait time before changing the message again

    return () => clearInterval(interval);
  }, []);
  // For Notifaction
  useEffect(() => {
    if (res.data === "OTP Sent") {
      toast.success("OTP Sent", {
        hideProgressBar: true,
      });
    }
  }, [res]);
  const validateIndianMobile = (number) => {
    const indianMobileRegex = /^[6-9]\d{0,9}$/;
    return indianMobileRegex.test(number);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\s/g, "").slice(0, 10);
    if (validateIndianMobile(sanitizedValue) || sanitizedValue === "") {
      setFormData({ ...formData, [name]: sanitizedValue });
    }
    const indianPhoneNumberPattern = /^[6-9]\d{9}$/;
    if (
      indianPhoneNumberPattern.test(sanitizedValue) ||
      indianPhoneNumberPattern.test(location?.state?.mobile)
    ) {
      setIsPhoneNumberValid(true);
      setErrorMessage("");
    } else {
      setIsPhoneNumberValid(false);
      if (isBlurred) {
        setErrorMessage(
          sanitizedValue.length === 0
            ? "Please enter your mobile number."
            : "Please enter a valid 10-digit Indian mobile number starting with 6-9"
        );
      }
    }
  };

  const handleBlur = () => {
    setIsBlurred(true);
    if (!isPhoneNumberValid && !formData.number) {
      setErrorMessage(
        formData.number.length === 0
          ? "Please enter your mobile number."
          : "Please enter a valid 10-digit Indian mobile number starting with 6-9"
      );
    }
  };

  // For Form Submit and API handling Start
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (formData.number) {

      GENERATEOTP(formData.number, (otpResponse, err) => {       
        if (otpResponse) {
          setRes(otpResponse);
          // setOtp(otpResponse.data);  // Assuming response.data contains the OTP
          console.log("OTP generated:", otpResponse);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(err?.message || "Failed to generate OTP", {
            hideProgressBar: true,
          });
        }
      });


      const userTackApiData = () => {
        apiService
          .userTrackData(formData.number)
          .then((res) => {
            UserTrack(res?.data?.response); // Process response
          })
          .catch((err) => {
            console.error(
              "Error in userTrackData:",
              err.message,
              err.response?.data
            );
          });
      };

      // Call the function
      userTackApiData();
    }
  };

  // For Form Submit and API handling end

  // For Form Submit and API handling end

  const handleChangeOtp = (newValue) => {
    setOtp(newValue);
  };
  const handleKeyPress = (e) => {
    if (
      e.key === "Enter" &&
      otp.length === 4 &&
      (userTrack?.tncConsentFlag ||
        userTrack?.whatsAppConsentFlag ||
        userTrack?.experianConsentFlag)
    ) {
      handleSubmitOtp();
    }

    if (
      isChecked &&
      e.key === "Enter" &&
      otp.length === 4 &&
      (!userTrack?.tncConsentFlag ||
        !userTrack?.whatsAppConsentFlag ||
        !userTrack?.experianConsentFlag)
    ) {
      handleSubmitOtp();
    }
  };
  //Otp input validation start
  function matchIsString(text) {
    return typeof text === "string";
  }
  function matchIsNumeric(text) {
    const isNumber = typeof text === "number";
    const isString = matchIsString(text);
    return (isNumber || (isString && text !== "")) && !isNaN(Number(text));
  }
  const validateChar = (value, index) => {
    return matchIsNumeric(value);
  };
  //Otp input validation end
  const handleChnageNum = () => {
    setRes("");
    // setFormData({ ...formData, number: "" });
  };
  // for the resend Otp
  const handleResendOtp = () => {
    setLoading(true);
    if (formData.number) {
      // axiosInstance
      //   .post(`/tdf/api/generate/otp/${formData.number}`)
      //   .then((res) => {
      //     setRes(res);
      //     setLoading(false);
      //   })
      //   .catch((err) => {
      //     console.log("err:", err);
      //     setLoading(false);
      //   });
      const resendApiData = async () => {
        try {
          // Call the POST request to generate OTP
          // const res = await apiClient.post(API_ENDPOINTS.GET_OTP(formData.number));
          const res = await apiService.generateOtp(formData.number);

          setRes(res);
          setLoading(false);
        } catch (err) {
          console.log("err:", err);
          setLoading(false);
        }
      };
      resendApiData();

      // Start the countdown timer again after resending
      setTimer(60);
      setIsButtonDisabled(true);
    }
  };
  // Countdown Timer Effect
  useEffect(() => {
    let countdown;
    if (timer > 0 && isButtonDisabled) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsButtonDisabled(false);
      clearInterval(countdown);
    }
    return () => clearInterval(countdown);
  }, [timer, isButtonDisabled]);
  // for the submit Otp
  const handleSubmitOtp = () => {
    setLoading(true);
    if (formData.number) {
      AUTHTOKEN(
        {
          username: formData.number,
          password: otp,
        },
        (res, err) => {
          if (res) {
            // setLoading(false);
            setAuthRes(res.data);
            localStorage.setItem("TDFToken", res.data.access_token);
            if (res.data && res.data.access_token) {
              const getLocalStorage = localStorage.getItem("TDFToken");
              profileApiData({}, (res, err) => {
                if (res) {
                  setUserStatus(res);
                  localStorage.setItem("StatusLS", res?.data?.status);
              // encryptionValues({ key: "StatusLS", value: res?.data?.status });
                  
                } else {
                }
              });
            }
            if (res?.data && res.data.access_token) {
              const getLocalStorage = localStorage.getItem("TDFToken");
              // geeting these LS from the FB Campaign API URL
              const utm_sourceFB = localStorage.getItem("utm_source");
              const utm_mediumFB = localStorage.getItem("utm_medium");
              const utm_campaignFB = localStorage.getItem("utm_campaign");
              const utm_contentFB = localStorage.getItem("utm_content");

              const utmCampaigns = localStorage.getItem("utmCampaign");

              let utmCampaign = "";
              let utmMedium = "";
              let utmSource = "";
              let utmContent = "";
              if (utmCampaigns) {
                // Convert utmCampaign to a URLSearchParams object
                const params = new URLSearchParams(utmCampaigns);

                // Extract the values
                utmCampaign = params.get("utm_campaign") || "";
                utmMedium = params.get("utm_medium") || "";
                utmSource = params.get("utm_source") || "";
                utmContent = params.get("utm_content") || "";
              }

              const token = getLocalStorage;
              if (!userTrack?.tncConsentFlag) {
                const requestData = {
                  tncConsentFlag: true,
                  ...(utm_campaignFB || utmCampaign
                    ? { utmCampaign: utm_campaignFB || utmCampaign }
                    : {}),
                  ...(utm_mediumFB || utmMedium
                    ? { utmMedium: utm_mediumFB || utmMedium }
                    : {}),
                  ...(utm_sourceFB || utmSource
                    ? { utmSource: utm_sourceFB || utmSource }
                    : {}),
                  ...(utm_contentFB || utmContent
                    ? { utmContent: utm_contentFB || utmContent }
                    : {}),
                };
                axiosInstance
                  .post("/auth/userTrack/saveUserTrack", requestData, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  })
                  .then((res) => {});

                // saveUserDataApi(requestData, (res, err) => {
                //   if ((res, err)) {
                //   } else {
                //     console.log("err:", err);
                //   }
                // });
              }
            }
          } else {
            console.log(err?.message);
            toast.error("Invalid OTP", {
              hideProgressBar: true,
            });
            setLoading(false);
            setOtp("")
          }
        }
      );
    }
  };
  useEffect(() => {
    if (userStatus?.status == 200) {
      USER_REPORT((Apidata, err) => {
        console.log("Apidata:", Apidata);
        console.log("userStatus:", userStatus);
        setLoading(false);

        if (userStatus?.data?.status === "BUREAU_FETCH") {
          navigate("/dashboard/reports", {
            state: { userProfileData: userStatus?.data },
          });
          return;
        }
        //
        if (!Apidata?.data?.data && Apidata?.data?.openForm === true) {
          switch (userStatus?.data?.status) {
            case "ENHANCED_FETCH_REPORT_OTP_VALIDATED":
            case "NORMAL_FETCH_REPORT_OTP_SENT":
            case "NORMAL_FETCH_REPORT_OTP_VALIDATED":
              navigate("/login/experianfullform", {
                state: { userProfileData: userStatus?.data },
              });
              break;

            case "ENHANCED_FETCH_REPORT_OTP_SENT":
              navigate("/login/user", {
                state: { userProfileData: userStatus?.data },
              });
              break;

            default:
              navigate("/login/user", {
                state: { userProfileData: userStatus?.data },
              });
              localStorage.setItem("mobile", formData?.number);
              break;
          }
        } else if (!Apidata?.data?.data && Apidata?.data?.openForm === false) {
          switch (userStatus?.data?.status) {
            case "ENHANCED_FETCH_REPORT_OTP_VALIDATED":
            case "NORMAL_FETCH_REPORT_OTP_SENT":
            case "NORMAL_FETCH_REPORT_OTP_VALIDATED":
              navigate("/login/experianfullform", {
                state: { userProfileData: userStatus?.data },
              });
              break;

            case "ENHANCED_FETCH_REPORT_OTP_SENT":
              navigate("/login/user", {
                state: { userProfileData: userStatus?.data },
              });
              break;
            case "REVOKE_CONSENT":
            case "BUREAU_DELETED":
            case "OTP_VALIDATED":
              navigate("/login/user", {
                state: { userProfileData: userStatus?.data },
              });
              localStorage.setItem("mobile", formData?.number);

              break;
            case null:
              navigate("/login/user", {
                state: { userProfileData: userStatus?.data },
              });
              localStorage.setItem("mobile", formData?.number);

              break;

            default:
              navigate("/login/user", {
                state: { userProfileData: userStatus?.data },
              });
              localStorage.setItem("mobile", formData?.number);
              break;
          }
        } else {
          switch (userStatus?.data?.status) {
            case "REVOKE_CONSENT":
            case "BUREAU_DELETED":
            case "OTP_VALIDATED":
              navigate("/login/user", {
                state: { userProfileData: userStatus?.data },
              });
              localStorage.setItem("mobile", formData?.number);

              break;
            case "ENHANCED_FETCH_REPORT_OTP_VALIDATED":
            case "NORMAL_FETCH_REPORT_OTP_SENT":
            case "NORMAL_FETCH_REPORT_OTP_VALIDATED":
              navigate("/login/experianfullform", {
                state: { userProfileData: userStatus?.data },
              });
              break;

            case "ENHANCED_FETCH_REPORT_OTP_SENT":
              navigate("/login/user", {
                state: { userProfileData: userStatus?.data },
              });
              break;

            case null:
              navigate("/login/user", {
                state: { userProfileData: userStatus?.data },
              });
              localStorage.setItem("mobile", formData?.number);

              break;

            default:
              navigate("/login/user", {
                state: { userProfileData: userStatus?.data },
              });
              break;
          }
        }
      });
    } else if (
      [201, 301, 400, 401, 403, 404, 500].includes(userStatus?.status)
    ) {
      const errorMessages = {
        201: "Something went wrong. Please try again.",
        301: "Something went wrong. Please try again.",
        400: "Bad request. Please check your input and try again.",
        401: "Unauthorized. Please log in again.",
        403: "Access denied. You do not have permission to view this.",
        404: "Requested resource not found.",
        500: "Server error. Please try again later.",
      };
      toast.error(
        errorMessages[userStatus?.status] || "An unknown error occurred."
      );
    }
  }, [userStatus]);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <>
      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(252, 240, 240, 0.5)", // Semi-transparent background
            zIndex: 1300, // Higher than other elements
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={60} sx={{ color: "#ffffff" }} />
        </Box>
      )}
      <>
        <HeaderComponent />

        <Box></Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: {
              xs: "none",
              sm: "none",
              md: "90vh",
              lg: "90vh",
            },
          }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              lg={6}
              md={6}
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: {
                  xs: "40px",
                  sm: "30px",
                  md: "0",
                  lg: "0",
                },
              }}
            >
              <Box
                sx={{
                  textAlign: "center",
                  marginTop: {
                    xs: "0",
                    sm: "20px",
                    md: "20px",
                    lg: "20px",
                  },
                }}
              >
                <img
                  src={pullimg}
                  alt="pullLogo"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    width: "65%",
                    // marginTop: "20px",
                  }}
                />
                <Box
                  sx={{
                    height: "120px",
                    display: "flex",
                    justifyContent: "center",
                    padding: "0 20px",
                  }}
                >
                  <Typography
                    sx={{
                      // maxWidth: "65%",
                      maxWidth: {
                        xs: "100%",
                        lg: "65%",
                        sm: "65%",
                        md: "65%",
                      },
                      height: "auto",
                      textAlign: "center",
                      fontSize: "20px",
                      opacity: fade ? 1 : 0,
                      transition: "opacity 0.5s ease-in-out",
                      fontFamily: "CustomFontMedium",
                    }}
                  >
                    {messages[currentMessageIndex]}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={6}
              md={6}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: {
                  xs: "center",
                  md: "center",
                  lg: "flex-start",
                },
                marginTop: { xs: "20px", sm: "20px", md: "0" },
              }}
            >
              <Box
                sx={{
                  // border: "2px solid whitesmoke",
                  margin: "20px",
                  // width: {sx: "none", sm: "none", lg: "65%", },
                  width: { xs: "100%", sm: "65%", lg: "65%" },

                  // marginTop: "50px"
                  // marginTop: { sm: "120px", lg: "120px", sx: "50px" },
                  // background: "#FFFFFF",
                  // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "5px",
                }}
              >
                <form onSubmit={handleSubmit}>
                  <Box
                    style={{
                      padding: "40px",
                      boxShadow:
                        "0 5px 5px -3px rgba(0,0,0,.2),0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12)",
                      borderRadius: "5px",
                    }}
                  >
                    {res.data == "OTP Sent" ? (
                      <>
                        <Typography
                          variant="h5"
                          style={{
                            marginBottom: "20px",
                            fontSize: "24px",
                            fontWeight: "400",
                            fontFamily: "CustomFontMedium",
                          }}
                        >
                          Verify your mobile number
                        </Typography>
                        <Typography
                          variant="h5"
                          style={{
                            marginBottom: "20px",
                            fontSize: "18px",
                            fontFamily: "CustomFontMedium",
                          }}
                        >
                          Enter the OTP you received for verification
                        </Typography>
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontFamily: "CustomFontMedium",
                          }}
                        >
                          <Typography>+91-</Typography>
                          <Typography style={{ marginLeft: "5px" }}>
                            {formData?.number}
                          </Typography>
                          <Button
                            variant="text"
                            size="small"
                            style={{
                              textTransform: "capitalize",
                              color: "#044a67",
                              marginLeft: "10px",
                              fontFamily: "CustomFontMedium",
                            }}
                            onClick={handleChnageNum}
                          >
                            Change Number
                          </Button>
                        </Box>
                        <MuiOtpInput
                          value={otp}
                          onChange={handleChangeOtp}
                          onKeyDown={handleKeyPress}
                          TextFieldsProps={{
                            placeholder: "-",
                            size: "medium",
                            inputProps: {
                              style: {
                                fontWeight: "bold",
                              },
                              inputMode: "numeric",
                            },
                          }}
                          type="number"
                          validateChar={validateChar}
                          style={{ marginTop: "10px" }}
                        />
                        {userTrack?.tncConsentFlag ||
                        userTrack?.whatsAppConsentFlag ||
                        userTrack?.experianConsentFlag ? (
                          <></>
                        ) : (
                          <>
                            <Box style={{ marginTop: "20px" }}>
                              <FormControlLabel
                                control={
                                  <Checkbox style={{ color: "#044a67" }} />
                                }
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                                label={
                                  <Box
                                    style={{
                                      display: "flex",
                                      //  whiteSpace:"pre"
                                    }}
                                  >
                                    <Typography style={{ fontSize: "12px" }}>
                                      I agree to
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: "12px",
                                        color: "#044a67",
                                        marginLeft: "5px",
                                      }}
                                      onClick={handleOpenModal}
                                    >
                                      terms & conditions.*
                                    </Typography>
                                  </Box>
                                }
                              />
                            </Box>
                          </>
                        )}

                        <Button
                          variant="text"
                          size="small"
                          style={{
                            textTransform: "capitalize",
                            color: isButtonDisabled ? "" : "#044a67",
                            marginTop: "10px",
                          }}
                          onClick={handleResendOtp}
                          disabled={isButtonDisabled}
                        >
                          {" "}
                          Resend OTP
                          {isButtonDisabled ? `(${timer}s)` : ""}
                        </Button>
                        <Box style={{ marginTop: "20px" }}>
                          {!userTrack?.tncConsentFlag &&
                          !userTrack?.whatsAppConsentFlag &&
                          !userTrack?.experianConsentFlag ? (
                            <>
                              <Button
                                variant="contained"
                                style={{
                                  background:
                                    otp.length === 4 && isChecked
                                      ? "#044A67"
                                      : "#d3d3d3",
                                  color:
                                    otp.length === 4 && isChecked
                                      ? "#FFFFFF"
                                      : "rgba(0, 0, 0, .26)",
                                  width: "100%",
                                  textTransform: "capitalize",
                                }}
                                onClick={handleSubmitOtp}
                                disabled={
                                  otp.length !== 4 ||
                                  (!userTrack?.tncConsentFlag &&
                                    !userTrack?.whatsAppConsentFlag &&
                                    !userTrack?.experianConsentFlag &&
                                    !isChecked) ||
                                  loading
                                }
                              >
                                <> Verify and Login</>
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                variant="contained"
                                style={{
                                  background:
                                    otp.length === 4 ? "#044A67" : "#d3d3d3",
                                  color:
                                    otp.length === 4
                                      ? "#FFFFFF"
                                      : "rgba(0, 0, 0, .26)",
                                  width: "100%",
                                  textTransform: "capitalize",
                                }}
                                onClick={handleSubmitOtp}
                                disabled={otp.length !== 4 || loading}
                              >
                                <> Verify and Login</>
                              </Button>
                            </>
                          )}
                        </Box>
                      </>
                    ) : (
                      <>
                        <Typography
                          variant="h5"
                          style={{
                            marginBottom: "20px",
                            fontFamily: "CustomFontMedium",
                          }}
                        >
                          Hi Welcome!
                        </Typography>
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: "400",
                            fontFamily: "CustomFontMedium",
                          }}
                        >
                          Phone Number
                        </div>
                        <TextField
                          type="number"
                          style={{ width: "100%", marginTop: "5px" }}
                          placeholder="Enter Mobile Number"
                          value={formData.number}
                          name="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id="phone-input"
                        />
                        {/* Default message while field is empty or user hasn't blurred the field */}
                        {!isBlurred &&
                          (formData?.number === "" ||
                            formData.number.length < 10) && (
                            <Typography
                              style={{
                                color: "rgba(0,0,0,.6)",
                                fontSize: "12px",
                                marginLeft: "10px",
                                fontFamily: "CustomFontMedium",
                                marginTop: ".6666666667em",
                              }}
                            >
                              You will receive a 4-digit code to verify your
                              details.
                            </Typography>
                          )}
                        {/* Message if phone number is valid */}
                        {isPhoneNumberValid &&
                          formData.number.length === 10 &&
                          !errorMessage && (
                            <Typography
                              style={{
                                color: "rgba(0,0,0,.6)",
                                fontSize: "12px",
                                marginLeft: "10px",
                              }}
                            >
                              You will receive a 4-digit code to verify your
                              details.
                            </Typography>
                          )}
                        {/* Error message */}
                        {errorMessage && (
                          <Typography
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginTop: "5px",
                              marginLeft: "10px",
                            }}
                          >
                            {errorMessage}
                          </Typography>
                        )}

                        <Button
                          variant="contained"
                          // disabled={formData.number.length !== 10 }
                          disabled={!isPhoneNumberValid}
                          // style={{ background: "#044A67", marginTop: "40px", width: "100%" }}
                          style={{
                            background:
                              formData.number.length === 10 &&
                              isPhoneNumberValid
                                ? "#044A67"
                                : "#d3d3d3",
                            color:
                              formData.number.length === 10 &&
                              isPhoneNumberValid
                                ? "#FFFFFF"
                                : "rgba(0, 0, 0, .26)",
                            marginTop: "40px",
                            width: "100%",
                          }}
                          type="submit"
                          endIcon={<SendIcon />}
                        >
                          <>Send OTP</>
                        </Button>
                      </>
                    )}
                  </Box>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* Modal Start */}

        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Container
            className="modal-container"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: { xs: "90vh", md: "80vh", lg: "80vh" },
              // maxHeight: "80vh",
              backgroundColor: "white",
              padding: { xs: "10px", md: "20px" },
              borderRadius: "20px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
              width: {
                lg: "80%",
                sm: "80%",
                xs: "90%",
              },
              overflowY: "hidden", // Hide overflow on the container
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                overflowY: "auto", // Allow scrolling within this box
                paddingRight: "10px", // Add some padding to prevent the scrollbar from overlapping content
              }}
            >
              <TermsConditions />
            </Box>

            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCloseModal}
                style={{
                  background: "#05517a",
                  color: "white",
                  borderRadius: "10px",
                  marginTop: "20px",
                  fontFamily: "CustomFontMedium",
                }}
              >
                Close
              </Button>
            </Box>
          </Container>
        </Modal>
        {/* Modal End */}
      </>
    </>
  );
}

export default Login;
