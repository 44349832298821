import React, { useEffect, useState } from 'react';
import './style.css';
import { Chart } from "react-google-charts";
import CreditWorthiness from './CreditWorthiness';
import exp_img from "../assets/imgs/Experian_logo.svg"
import EnquiryVelocity from './EnquiryVelocity';
import ScoreGauge from './ScoreGauge';

const CreditHistoryTimeline = ({ propData, summary }) => {
    const {
        payment_history_analysis,
        outstanding_balances_accounts_summary,
        credit_utilization_insights
    } = summary;
    const [isSmall, setIsSmall] = useState(false);
    const { creditHistoryTimeLine } = propData ?? {};
    const CalculateMaxInArray = () => {
        let max = 0;
        for (let i = 1; i < data.length; i++) {
            if (data[i][1] > max) {
                max = data[i][1];
            }
        }
        return max;
    }

    const data = creditHistoryTimeLine ?? [
        ["", "Bad Accounts", "Good Accounts"],
        ["2017", 4, 0],
        ["2018", 5, 1],
        ["2019", 5, 1],
    ];
    const maxValueOnY = CalculateMaxInArray(creditHistoryTimeLine)

    const options = {
        colors: ["#2C2A4A", "#DABFFF"],
        legend: { position: "none" },
        hAxis: {
            title: "",
        },
        vAxis: {
            title: "",
            gridlines: {
                count: 5,
            },
            viewWindow: {
                min: 0,
                max: maxValueOnY && 15,
            },
        },
    };
    useEffect(() => {
        const handleResize = () => {
            setIsSmall(window.innerWidth < 580);
        };

        handleResize(); // Check on mount
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (<>
        <div className='premium-report-first-dash-2'>
            <div className='premium-report-first-dash-2-head'>
                <span> Credit Journey Snapshot</span>
            </div>
            <div className='premium-report-first-dash-2-chart'>
                <div className='premium-report-first-dash-2-chart-1'>
                    <Chart
                        chartType="Bar"
                        width="100%"
                        height="250px"
                        data={isSmall ? data.slice(0, 7) : data}
                        options={options}
                        loader={<div>Loading information...</div>}
                    />
                </div>
                <div className='premium-report-first-dash-2-chart-2'>
                    <div>
                        <span style={{ background: "#DABFFF" }}></span>
                        <p>Good loans</p>
                    </div>
                    <div>
                        <span style={{ background: "#2C2A4A" }}></span>
                        <p>Bad loans</p>
                    </div>
                </div>
                <div className='premium-report-first-dash-2-chart-3' >
                    <p>

                        {credit_utilization_insights ? credit_utilization_insights :
                            <>
                                <b>Consistent Repayment: </b>
                                By making timely payments on your loans and reducing overdue amounts,
                                you're gradually building a positive repayment history, which strengthens your credit profile.
                            </>}

                    </p>
                    <p>

                        {outstanding_balances_accounts_summary ? outstanding_balances_accounts_summary :
                            <>
                                <b>Reduction of High-Risk Loans: </b>
                                Actively paying off or closing high-risk loans, such as
                                unsecured credit or loans with high interest rates, helps reduce
                                financial risk and boosts your creditworthiness.
                            </>}

                    </p>
                    <p>

                        {payment_history_analysis ? payment_history_analysis :
                            <>
                                <b>Lower Credit Utilization:  </b>
                                As you manage your existing loans efficiently, your credit
                                utilization decreases, showing lenders that you're responsibly
                                managing your credit, which leads to improved credit health.
                            </>}
                    </p>

                </div>
            </div>
        </div>
    </>)
}

const CreditScoreSteppedLineChart = ({ propData }) => {
    const { scoreTrendLine } = propData;
    const [isSmall, setIsSmall] = useState(false);
    const [data, setData] = useState([
        ["Month", "Credit Score", { role: "tooltip", type: "string", p: { html: true } }, { role: "style" }],
        ["Sep 24", 205, "No Change"],
        ["Oct 24", 505, "Increase"],
    ]);
    const [graphRange, setGraphRange] = useState({ maxScore: 900, minScore: 600 })

    const scoreMapping = [
        { range: [0, 300], label: 'NEW TO CREDIT', color: "#FF5733" },
        { range: [300, 400], label: 'NEED ATTENTION', color: "#FF914D" },
        { range: [400, 500], label: 'POOR', color: "#FFB533" },
        { range: [500, 600], label: 'BELOW AVERAGE', color: "#FFDB33" },
        { range: [600, 700], label: 'AVERAGE', color: "#90CD4F" },
        { range: [700, 750], label: 'GOOD', color: "#90CD4F" },
        { range: [750, 800], label: 'GREAT', color: "#76FF33" },
        { range: [800, 900], label: 'EXCELLENT', color: "#4CAF50" }
    ];
    const getScoreLabelAndColor = (score) => {
        const result = scoreMapping.find(item => score >= item.range[0] && score < item.range[1]);
        return result ? { label: result.label, color: result.color } : { label: "UNKNOWN", color: "#000000" };
    };
    const getTrendColor = (trend) => {
        switch (trend) {
            case "Increase":
                return "#90CD4F"; // Green
            case "Decrease":
                return "#FF4040"; // Red
            case "No Change":
                return "#000000"; // Black
            default:
                return "#000000"; // Default black for unknown trends
        }
    };
    const findMaxMinScores = (data) => {
        const scores = data.slice(1).map(item => item[1]);
        const maxScore = Math.max(...scores);
        const minScore = Math.min(...scores);
        return { maxScore: maxScore >= 880 ? 900 : maxScore + 20, minScore: minScore - 20 };
    };

    useEffect(() => {
        const tempData = scoreTrendLine.slice(1).map(([month, creditScore, trend], index, arr) => {
            const { label, color } = getScoreLabelAndColor(creditScore);
            const trendColor = index == arr.length - 1 ? "#b8b1b1" : getTrendColor(trend);
            return [
                month,
                creditScore,
                `<div class="custom-tooltip">
                    ${month}
                    <br>
                    <span class="highlight">
                      ${label}
                       <br>
                      ${index == arr.length - 1 ? "Predicted" : ""}
                    </span>
                </div>`,
                trendColor
            ];
        });

        setData([
            ["Month", "Line 1", { role: "tooltip", type: "string", p: { html: true } }, { role: "style" }],
            ...tempData
        ]);
        setGraphRange(findMaxMinScores(scoreTrendLine));
    }, [propData]);

    const options = {
        title: "",
        hAxis: {
            title: "",
        },
        vAxis: {
            title: "",
            viewWindow: { min: graphRange.minScore, max: graphRange.maxScore },
            // viewWindow: { min: 0, max: 900 },
        },
        legend: "none",
        lineWidth: 3,
        pointSize: 7,
        series: {
            0: {
                type: "line", // Line 1 is a solid line
                lineWidth: 3,
                color: "black",
                pointShape: "circle",
                visibleInLegend: false,
            },
            1: {
                type: "line", // Line 2 is a dashed line
                lineWidth: 3,
                color: "#ccc",
                lineDashStyle: [4, 4], // Dashed line style for Line 2
                visibleInLegend: false,
            },
        },
        chartArea: {
            left: 40,
            top: 20,
            right: 20,
            bottom: 40,
            width: "100%",
            height: "80%",
        },
        titleTextStyle: {
            fontSize: 14,
            fontFamily: "'Nunito Sans', sans-serif",
            fontWeight: 500,
        },
        tooltip: { isHtml: true },  // Custom tooltip and disable default tooltip
    };
    const data2 = [
        ["Month", "Line 1", "Line 2", { role: "tooltip", type: "string", p: { html: true } }],
        ["Sep 24", 605, null, "Line 1: 205"],
        ["Oct 24", 601, null, "Line 1: 505, Line 2: 405"],
        ["Nov 24", 605, 605, "Line 1: 605, Line 2: 505"],
        ["Dec 24", null, 615, "Line 1: 705, Line 2: 605"],
    ];
    useEffect(() => {
        const handleResize = () => {
            setIsSmall(window.innerWidth < 580);
        };

        handleResize(); // Check on mount
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (

        <div className='premium-report-first-dash-12-chart'>
            <div className='premium-report-first-dash-12-chart-1'>
                <Chart
                    chartType="ComboChart"
                    width={isSmall ? "100%" : "90%"}
                    height="300px"
                    data={data}
                    options={options}
                    loader={<div>Loading information...</div>}
                />
            </div>
            <div className='premium-report-first-dash-12-chart-2'>
                <div>
                    <span style={{ background: "#90CD4F" }}></span>
                    <p>Increase</p>
                </div>
                <div>
                    <span style={{ background: "#FF4040" }}></span>
                    <p>Decrease</p>
                </div>
                <div>
                    <span style={{ background: "#000000" }}></span>
                    <p>No change</p>
                </div>
            </div>
        </div>
    );
};



const PremiumReport = ({ data }) => {
    const [mainReport, setMainReport] = useState(
        {
            overallDelinquencyData: {
                "2022": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                "2023": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                "2024": [0, 0, 0, 0, 0, 0, 0, 0, -1, -1, -1, -1]
            },
            creditWorthinessTimeLine: [
                ["Month", "Late Payments", "On-Time Payments"],
                ["Aug-2023", 0, 1],
                ["Sep-2023", 0, 1],
                ["Nov-2023", 0, 1],
                ["Dec-2023", 0, 4],
                ["Jan-2024", 0, 2],
                ["Feb-2024", 0, 2],
                ["Mar-2024", 0, 2],
                ["Apr-2024", 0, 2],
                ["May-2024", 1, 1],
                ["Jun-2024", 1, 3]
            ],
            creditHistoryTimeLine: [
                ["", "Bad Accounts", "Good Accounts"],
                ["2017", 4, 0],
                ["2018", 5, 1],
                ["2019", 5, 1],
            ],
            scoreTrendLine: [
                ["Month", "Credit Score", "Trend"],
                ["Sep 24", 205, "No Change"],
                ["Oct 24", 305, "Increase"],
            ],
            bureauInsightRemarks: {},
            createdDate: "",
            creditScore: "",
            dateOfReport: ""
        }
    );
    const [isSmall, setIsSmall] = useState(false);
    const generateKeyValueSummary = (inputObject) => {
        const formattedObject = {};

        Object.keys(inputObject).forEach((key) => {
            // Convert the key to lowercase, replace spaces and special characters with underscores
            const formattedKey = key.toLowerCase()
                .replace(/[^a-zA-Z0-9]/g, '_')
                .replace(/__+/g, '_')  // replace multiple underscores with a single one
                .replace(/(^_+|_+$)/g, '');  // trim leading or trailing underscores

            formattedObject[formattedKey] = inputObject[key];
        });

        return formattedObject;
    };
    const formattedReportSummary = generateKeyValueSummary(mainReport?.bureauInsightRemarks, { "-----": "------", });
    const dateStampToDate = (timestamp) => {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[date.getMonth()]; // Get month abbreviation
        const day = date.getDate(); // Get day without padding
        return `${day} ${month}, ${year}`;
    }
    useEffect(() => {
        const handleResize = () => {
            setIsSmall(window.innerWidth < 580);
        };

        handleResize(); // Check on mount
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        setMainReport(data);
    }, [data]);
    return (
        <>
            <div className='class-main-primium-report padding-new-class'>
                <div className='premium-report-body'>
                    <div className='premium-report-head' style={{ display: isSmall ? "grid" : "flex" }}>
                        <span>Credit Report</span>
                        <p style={{
                            margin: isSmall ? "2px 0px" : 0,
                            opacity: isSmall ? .6 : 1
                        }}>
                            <b>Last updated on : </b> {dateStampToDate(mainReport?.dateOfReport)}
                        </p>
                    </div>
                    <div className='premium-report-first-dash'>
                        <div className='premium-report-first-dash-1'>
                            <div className='premium-report-first-dash-11'>
                                {/* <Gauge value={732} /> */}
                                <ScoreGauge score={mainReport?.creditScore} />
                                <p>{
                                    formattedReportSummary ?
                                        formattedReportSummary?.credit_score_overview
                                        :
                                        <>
                                            You have a good credit score! This makes you preferred by financial
                                            institutions to lend to you. You should expect higher credit
                                            for low credit cost (interest rates) and you will be able to
                                            get credit for longer tenure. Your credit score will enable
                                            loans with a minimal paperwork
                                        </>
                                }</p>
                            </div>
                            <div className='premium-report-first-dash-12'>
                                <div className='premium-report-first-dash-12-head'>
                                    <span>Credit Score Tracker</span>
                                </div>
                                <CreditScoreSteppedLineChart propData={mainReport} />
                            </div>
                        </div>
                        <CreditHistoryTimeline propData={mainReport} summary={formattedReportSummary} />
                    </div>
                </div>
                <CreditWorthiness propData={mainReport} />
                {/* <EnquiryVelocity /> */}
            </div>
        </>
    )
}

export default PremiumReport;