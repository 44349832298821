import React from 'react'
import { Box, Button, Grid, Typography,Hidden  } from "@mui/material";
import credilio from "../assets/imgs/credilio.webp";

function CredilioCard() {
  const handleCredilioNavgate =()=>{
    window.open("https://customer.credilio.in/credit-card/utm-broadcast/sbm-credilio/?utm_advisor_code=CRD0132323", "_blank");
  }
  return (
    <>
          {/* Custom BannerCard Start */}  
       {/* Custom BannerCard for Desktop */}
       <Hidden smDown>
        <Grid  container spacing={2} style={{ marginTop: "10px" }}>
          <Grid
          item
            xs={12}
            style={{
              borderRadius: "10px",
              background:
                "radial-gradient(circle, #103f49 18%, rgba(2, 0, 36, 1) 68%, rgb(89 89 89) 96%)",
              display: "flex",
              marginLeft: "15px",
            }}
          >
            <Grid item xs={12} sm={6} lg={6}>
              <Box
                style={{
                  height: "30vh",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={credilio}
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                  alt="Credit Card"
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={6} sx={{display:"flex", justifyContent:"center",alignItems:"center"}}>
              <Box style={{ padding: "20px" }}>
                <Typography
                  style={{
                    color: "#fff",
                    fontSize: "30px",
                    lineHeight: "35px",
                    marginTop:"10px",
                    marginBottom: "10px",
                    fontFamily:"CustomFontMedium"
                  }}
                >
                  Struggling to Boost Your Credit Score?
                </Typography>
                <Typography
                  style={{ color: "#fff", lineHeight: "16px", fontSize: ".9rem", 
                    marginTop:"15px",
                    fontFamily:"CustomFontMedium"
                  }}
                >
                  Apply for SBM Credilio Credit Card with DueFactory and Improve
                  your Credit Score.
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    marginTop: "50px",
                    width: "100%",
                    backgroundColor: "#e8c138",
                    color: "black",
                    fontWeight: "600",
                    fontFamily:"CustomFontMedium",
                    "&:hover": {
                      backgroundColor: "#2196F3",
                      color: "#fff",
                    },
                    
                  }}
                  onClick={handleCredilioNavgate}
                >
                  NO BUREAU | NO DOCUMENTATION
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      {/* Custom BannerCard for Mobile */}
      <Hidden mdUp smUp>
        <Grid container spacing={2} style={{ marginTop: "10px" }}>
          <Grid
            item
            xs={12}
            style={{
              borderRadius: "10px",
              background:
                "radial-gradient(circle, #103f49 18%, rgba(2, 0, 36, 1) 68%, rgb(89 89 89) 96%)",
              display: "flex",
              marginLeft: "15px",
              flexDirection: "column",
            }}
          >
            <Grid item xs={12}>
              <Box
                style={{
                  height: "30vh",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={credilio}
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                  alt="Credit Card"
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box style={{ padding: "20px" }}>
                <Typography
                  style={{
                    color: "#fff",
                    fontSize: "1.3rem",
                    lineHeight: "25px",
                    marginBottom: "10px",
                  }}
                >
                  Struggling to Boost Your Credit Score?
                </Typography>
                <Typography
                  style={{ color: "#fff", lineHeight: "16px", fontSize: ".9rem" }}
                >
                  Apply for SBM Credilio Credit Card with DueFactory and Improve
                  your Credit Score.
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    marginTop: "20px",
                    width: "100%",
                    backgroundColor: "#e8c138",
                    color: "black",
                    fontWeight: "550",
                    "&:hover": {
                      backgroundColor: "#2196F3",
                      color: "#fff",
                    },
                  }}
                  onClick={handleCredilioNavgate}
                >
                  NO BUREAU | NO DOCUMENTATION
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      {/* Custom BannerCard end */}
    </>
  )
}

export default CredilioCard;