import React from "react";
import { Box } from "@mui/material";
import CustomTabs from "./CustomTabs";
import AccountCard from "../Dashboard/AccountCard";
import Divider from "@mui/material/Divider";

function TabsCoponent({ userData, }) {

  return (
    <CustomTabs accountData ={userData?.allAccounts?.accountsSummary}>
      <div title={`Bad Accounts (${userData?.badLoans?.accountDetails?.length})`}>
        <Divider />

        <Box style={{ padding: "20px", background: "#EBEBEB",fontFamily: "CustomFontMedium",  minHeight: "75vh", overflow:"auto",  maxHeight:"75vh", borderRadius:"0 0 10px 10px", border: "1px solid #D2D1D1"}}>
          <p style={{fontSize:"16px",padding:"10px", fontFamily:'Roboto,Helvetica Neue,sans-serif', color:"RGB(33, 37, 41)"}}>Loans that have not received payment for more than 90 days</p>
          <AccountCard userData={userData && userData?.badLoans} badLoans={"badLoans"} />
        </Box>
      </div>
      <div title= {`Active Accounts (${userData?.activeLoans?.accountDetails?.length})`}>
        <Divider />
        <Box style={{ padding: "20px", background: "#EBEBEB",fontFamily: "CustomFontMedium",  minHeight: "75vh", overflow:"auto",  maxHeight:"75vh", borderRadius:"0 0 10px 10px", border: "1px solid #D2D1D1" }}>
          <p style={{fontSize:"16px",padding:"10px", fontFamily:'Roboto,Helvetica Neue,sans-serif',color:"RGB(33, 37, 41)"}}>
            Loans that have received payment within 30 of payment date and still
            active.
          </p>
          <AccountCard userData={userData && userData?.activeLoans} />
        </Box>
      </div>
      <div title={`All Accounts (${userData?.allAccounts?.accountDetails?.length})`}>
        <Divider />
        <Box style={{ padding: "20px", background: "#EBEBEB",fontFamily: "CustomFontMedium",  minHeight: "75vh", overflow:"auto",  maxHeight:"75vh", borderRadius:"0 0 10px 10px", border: "1px solid #D2D1D1" }}>
          <p style={{fontSize:"16px",padding:"10px", fontFamily:'Roboto,Helvetica Neue,sans-serif',color:"RGB(33, 37, 41)"}}>Total number of open accounts with outstanding payments.</p>
          <AccountCard userData={userData && userData?.allAccounts} />
        </Box>
      </div>
      <div title={`Closed Accounts (${userData?.closed?.accountDetails?.length})`}>
        <Divider />
        <Box style={{ padding: "20px", background: "#EBEBEB",fontFamily: "CustomFontMedium", minHeight: "75vh", overflow:"auto",  maxHeight:"75vh",borderRadius:"0 0 10px 10px", border: "1px solid #D2D1D1"   }}>
          <p style={{fontSize:"16px",padding:"10px", fontFamily:'Roboto,Helvetica Neue,sans-serif',color:"RGB(33, 37, 41)"}}>Loans that have been fully paid off or settled.</p>

          <AccountCard userData={userData && userData?.closed} />
        </Box>
      </div>
    </CustomTabs>
  );
}

export default TabsCoponent;
