import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"; // Using react-icons for navigation arrows
import myth1 from "../assets/imgs/myth-1.webp";
import myth2 from "../assets/imgs/myth-2.webp";
import myth3 from "../assets/imgs/myth-3.webp";
import loans from "../assets/imgs/loanss.webp";
import income from "../assets/imgs/income.webp";
import "./carouselStyles.css";


const cardData = [
  {
    image: myth1,
    title: "I can not improve my credit score",
    text: " Of course you CAN! With Due Factory explore options to manage your credit health better and steadily bring up your credit score.",
  },
  {
    image: myth2,
    title: "Settling my loan will improve my credit score.",
    text: "When you settle a loan you pay less than the full amount to close an outstanding debt. So it does not improve your credit score. With Due Factory, get tailored options to close your open credit lines and improve your credit score.",
  },
  {
    image: myth3,
    title: "I should get a loan easily as I have never taken one before.",
    text: "If you have never taken a loan, but you have used a credit card and defaulted on payments, then your credit score has been impacted. Understand how to maintain a strong credit health with Due Factory.",
  },
  {
    image: income,
    title: "The higher my income, the higher will be my credit score.",
    text: "Contrary to popular belief, your income is not related to your credit score, but your loan repaying frequency is. If you have not made regular payments on loans or credit cards then your credit score will decrease. ",
  },
  {
    image: loans,
    title: "Multiple loan applications won't affect my credit score.",
    text: "Multiple loan applications can actually bring down your credit score because every time a lender raises a hard inquiry against your application, it lowers your credit score. Partner with Due Factory to manage your credit health better.",
  },
];

const NextArrow = ({ onClick }) => {
  return (
    <div onClick={onClick} className="nextArrow" >
      <FaArrowRight />
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div className="prevArrow" onClick={onClick}>
      <FaArrowLeft />
    </div>
  );
};

export default function SimpleSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <NextArrow />, 
    prevArrow: <PrevArrow />, 
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {cardData.map((card, index) => (
        <div key={index}>
          <div style={styles.card}>
            <img src={card.image} alt={card.title} style={styles.image} />
            <div style={styles.content}>
              <h3 style={styles.title}>{card.title}</h3>
              <p style={styles.text}>{card.text}</p>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
}

const styles = {
  card: {
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
    backgroundColor: "#fff",
    maxWidth: "450px",
    margin: "10px",
    textAlign: "center",
    height: "630px",
    padding: "20px",
  },
  image: {
    width: "100%",
    height: "auto",
  },
  content: {
    padding: "20px",
  },
  title: {
    fontSize: "25px",
    marginBottom: "10px",
    fontFamily: "CustomFontMedium",
  },
  text: {
    color: "#555",
    fontSize: "19px",
    fontFamily: "CustomFontMedium",
  },
 
};
