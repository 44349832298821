
const API_ENDPOINTS = {
    POST_INSIGHT: ()=> "/tdf/api/userBureauInsight/getInsight",
    GET_OTP: (number) => `/tdf/api/generate/otp/${number}`, 
    USER_GET_REPORT: () => "/tdf/api/user/getReport",
    POST_CONTACT_MSG: () => "/tdf/api/contactUsMsg",      
    POST_REVOKECONSENT: () => "/tdf/api/user/revokeConsent",  
    GET_USER_TRACK: (number) => `/auth/userTrack/getUserTrack/${number}`, 
    POST_AUTH_TOKEN: () => "/auth/login/token",     
    POST_SAVE_USER_TRACK: () => "/auth/userTrack/saveUserTrack",     
    POST_CALL_ME_BACK: () => "/tdf/api/user/callMeBack",      
    POST_GETING_PAYMENT_LINK: () => "/tdf/api/user/getPaymentLink",    
    POST_COUNTER_OFFER: () => "/tdf/api/counterOffer",     
    POST_SETTLEMENT_LISTING: () => "/tdf/api/user/settlement/listing",     
    GET_PROFILE_DETAIL: () => "/tdf/api/user/getProfileDetail",     
    POST_ENHANCED_FETCH_REPORT: () => "/tdf/api/user/enhancedFetchReport",                  
    GET_GET_STATTE: () => "/tdf/api/getState",    
    POST_FETCH_REPORT: () => "/tdf/api/user/fetchReport",  
    
    



    
  };
  
  export default API_ENDPOINTS;
  